/* eslint-disable */
import async from "async";
import axios from "axios";
import { Loading, Notification } from "element-ui";
import md5 from "@/utils/md5.min.js";
import { Message } from "element-ui";
import store from "@/store";
import router from "../router/index.js";
import moment from "moment";
import { userInfoCookie, menuListCookie } from "@/utils/auth";

export default {
  domain: process.env.NODE_ENV === "production" ? window.domain : "http://127.0.0.1:7001", // API接口地址
  templateDomain: window.templateDomain, // 模板下载地址
  ureportDomain: "http://39.99.242.248:8089", // 报表设计器
  ossDomain: window.templateDomain, // 国内oss
  uploadConf: window.uploadConf, // 阿里云oss配置
  // uploadConf: {
  //   region: "oss-cn-hangzhou",
  //   accessKeyId: "LTAI5tBKicQZjcf91p8HX3LG",
  //   accessKeySecret: "Uq88pZYL0xDVrJoJBMVzNsTC3wmIqM",
  //   bucket: "wlkgo-wms",
  //   stsToken: null
  // },
  // uploadConf: {
  //   region: "oss-cn-beijing",
  //   accessKeyId: "LTAI4GCBXHHTPSA6myCsEwFf",
  //   accessKeySecret: "cecCYLcaGfrid38nsehJ5HNPY1fiM7",
  //   bucket: "honglian-yrt-wms",
  //   stsToken: null
  // },
  headers: {},
  ajax: function(url, params, callback, loadingTarget) {
    // axios.defaults.timeout = 1000 * 60 * 2;
    var the = this;
    async.waterfall(
      [
        // 获得签名
        function(_callback) {
          var tokenKey = "$tokenInfo" + store.getters.userType;
          var tokenInfo = JSON.parse(localStorage.getItem(tokenKey) || "{}");
          if (!tokenInfo || !tokenInfo.signToken || Date.parse(tokenInfo.expireTime) - new Date().getTime() <= 0) {
            let _url = "/api/auth/getToken";
            let guid = the.getUserGuid();
            if (!guid) {
              return _callback(new Error("本地唯一标识不存在！"));
            }

            let _params = {
              guid: guid
            };

            let baseURL = the.domain;
            //  create an axios instance
            let request = axios.create({
              baseURL: baseURL, //  api 的 base_url
              timeout: 1000 * 60 * 20 // request timeout 20min
            });
            request.interceptors.request.use(
              function(config) {
                the.loading(loadingTarget);
                return config;
              },
              function(error) {
                // 关闭loading
                the.loaded(loadingTarget);

                return Promise.reject(error);
              }
            );
            request({
              url: _url,
              method: "get",
              params: _params
            })
              .then(response => {
                let res = the.objectToCase(response.data);
                tokenInfo = res.data;
                if (tokenInfo) {
                  localStorage.setItem(tokenKey, JSON.stringify(tokenInfo));
                } else {
                  localStorage.removeItem(tokenKey);
                }
                _callback(null, tokenInfo);
              })
              .catch(error => {
                if (error.response) {
                  //  请求已发出，但服务器响应的状态码不在 2xx 范围内
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                  Notification.error({
                    title: "错误",
                    message: "服务端错误：" + error.response.data.ExceptionMessage
                  });
                } else {
                  //  Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                }
                console.log(error.config);

                // 关闭loading
                the.loaded(loadingTarget);
                let data = {
                  result: false,
                  msg: "服务器无响应"
                };
                callback(data);
              });
          } else {
            _callback(null, tokenInfo);
          }
        },
        // 访问数据
        function(arg1, _callback) {
          var tokenKey = "$tokenInfo" + store.getters.userType;
          let baseURL = the.domain;
          //  create an axios instance
          let request = axios.create({
            baseURL: baseURL, //  api 的 base_url
            timeout: 1000 * 60 * 20 // request timeout 20min
          });
          request.interceptors.request.use(
            function(config) {
              // 前面信息
              var timestamp = new Date().valueOf();
              var nonce = Math.random()
                .toString(36)
                .substr(2);
              var guid = the.getUserGuid();
              var tokenInfo = arg1;
              if (!tokenInfo || !tokenInfo.signToken) {
                Notification.error({
                  title: "错误",
                  message: "未获取到签名token，无法执行操作！"
                });
                // 关闭loading
                the.loaded(loadingTarget);
                return;
              }
              var userInfo = the.getUserInfo();
              var accessToken = userInfo.accessToken;

              var signature = the.getSignature(timestamp, nonce, tokenInfo, params);
              var headers = Object.assign(
                {
                  timestamp: timestamp,
                  nonce: nonce,
                  guid: guid,
                  accessToken: accessToken,
                  signature: signature
                },
                the.headers
              );
              // 清空header，防止污染后面请求
              the.headers = {};

              config.headers = headers;
              the.loading(loadingTarget);

              return config;
            },
            function(error) {
              // 关闭loading
              the.loaded(loadingTarget);

              return Promise.reject(error);
            }
          );
          request({
            url: url,
            method: "post",
            data: params
          })
            .then(response => {
              var status = response.data.statusCode;
              if (status === 401 || status === 403 || status === 406) {
                var r = router;
                var redirect = r && r.app ? r.app.$route.path : "/";
                localStorage.removeItem(tokenKey);
                store.dispatch("LogOut").then(() => {
                  Message.error("系统未登录");
                  if (store.getters.userType === "consignor") {
                    location.href = `/user.html#/login?redirect=${redirect}`;
                  } else {
                    location.href = `/#/login?redirect=${redirect}`;
                  }
                  _callback(null, response);
                });
                // 关闭loading
                the.loaded(loadingTarget);
                return;
              }
              // 关闭loading
              the.loaded(loadingTarget);
              let data = response.data;
              _callback(null, data);
              callback(data);
            })
            .catch(error => {
              let data = {
                result: false,
                msg: null
              };
              if (error.response) {
                var status = error.response.status;
                //  请求已发出，但服务器响应的状态码不在 2xx 范围内
                if (status === 401 || status === 403 || status === 406) {
                  var r = router;
                  var redirect = r && r.app ? r.app.$route.path : "/";
                  localStorage.removeItem(tokenKey);
                  store.dispatch("LogOut").then(() => {
                    Message.error("系统未登录");
                    data.msg = "系统未登录";
                    if (store.getters.userType === "consignor") {
                      location.href = `/user.html#/login?redirect=${redirect}`;
                    } else {
                      location.href = `/#/login?redirect=${redirect}`;
                    }
                    _callback(null, error.response);
                  });
                  // 关闭loading
                  the.loaded(loadingTarget);
                  return;
                } else {
                  data.msg = "服务端错误：" + error.response.data.ExceptionMessage;
                }
              } else {
                callback({
                  result: false,
                  msg: "数据加载失败：" + error.message
                });
                data.msg = "数据加载失败：" + error.message;
              }
              callback(data);

              // 关闭loading
              the.loaded(loadingTarget);
            });
        }
      ],
      function(err, result) {
        //  执行的任务中方法回调err参数时，将被传递至本方法的err参数
        //  参数result为最后一个方法的回调结果'done'

        // 关闭loading
        the.loaded(loadingTarget);
      }
    );
  },
  ajax2: function(url, params, callback, loadingTarget) {
    var the = this;
    let request = axios.create({
      baseURL: the.domain, //  api 的 base_url
      timeout: 1000 * 60 * 20 // request timeout 20min
    });

    request.interceptors.request.use(
      function(config) {
        var guid = the.getUserGuid();
        var userInfo = the.getUserInfo();
        var accessToken = userInfo.accessToken;

        var headers = {
          guid: guid,
          accessToken: accessToken
        };
        config.headers = headers;
        the.loading(loadingTarget);

        return config;
      },
      function(error) {
        // 关闭loading
        the.loaded(loadingTarget);
        return Promise.reject(error);
      }
    );

    request({
      url: url,
      method: "post",
      data: params
    })
      .then(response => {
        let data = response.data;
        callback(data);

        // 关闭loading
        the.loaded(loadingTarget);
      })
      .catch(error => {
        if (error.response) {
          //  请求已发出，但服务器响应的状态码不在 2xx 范围内
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Notification.error({
            title: "错误",
            message: "服务端错误：" + error.response.data.ExceptionMessage
          });
        } else {
          //  Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);

        // 关闭loading
        the.loaded(loadingTarget);
      });
  },
  /**
   * 访问国内域名
   * @param {*} url
   * @param {*} params
   * @param {*} callback
   * @param {*} loadingTarget
   */
  ajax3: function(url, params, callback, loadingTarget) {
    var the = this;
    let request = axios.create({
      baseURL: process.env.INNER_API, //  api 的 base_url
      timeout: 1000 * 60 * 20 // request timeout 20min
    });

    request.interceptors.request.use(
      function(config) {
        var guid = the.getUserGuid();
        var userInfo = the.getUserInfo();
        var accessToken = userInfo.accessToken;

        var headers = {
          guid: guid,
          accessToken: accessToken
        };
        config.headers = headers;
        the.loading(loadingTarget);

        return config;
      },
      function(error) {
        // 关闭loading
        the.loaded(loadingTarget);
        return Promise.reject(error);
      }
    );

    request({
      url: url,
      method: "post",
      data: params
    })
      .then(response => {
        let data = response.data;
        callback(data);

        // 关闭loading
        the.loaded(loadingTarget);
      })
      .catch(error => {
        if (error.response) {
          //  请求已发出，但服务器响应的状态码不在 2xx 范围内
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Notification.error({
            title: "错误",
            message: "服务端错误：" + error.response.data.ExceptionMessage
          });
        } else {
          //  Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);

        // 关闭loading
        the.loaded(loadingTarget);
      });
  },
  /**
   * 将首字母小写
   * @param {String} str 需要转换的字符串
   * @param {String} type 转换类型，Upper或者Lower
   */
  caseStyle(str, type = "lower") {
    if (!str || str.length < 2) return str;
    if (type === "upper") {
      str = str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      str = str.charAt(0).toLowerCase() + str.slice(1);
    }
    return str;
  },
  /**
   * 将对象属性首字母转为大小写，默认转换为小写
   * @param {Object} obj 需要转换的对象
   * @param {String} type 转换类型，Upper或者Lower
   */
  objectToCase(obj, type = "lower") {
    if (!obj) return obj;

    var data = {};
    // 数据转换
    Object.keys(obj).forEach(key => {
      let val = obj[key];
      let _key = this.caseStyle(key, type);
      if (Array.isArray(val)) {
        data[_key] = this.arrayToCase(val);
      } else if (Object.prototype.toString.call(val) === "[object Object]") {
        data[_key] = this.objectToCase(val);
      } else {
        data[_key] = val;
      }
    });
    return data;
  },
  /**
   * 将数组属性首字母转为大小写，默认转换为小写
   * @param {Array} obj 需要转换的数组
   * @param {String} type 转换类型，Upper或者Lower
   */
  arrayToCase(obj, type = "lower") {
    if (!obj) return obj;

    if (!Array.isArray(obj)) {
      return obj;
    }

    let _arrList = [];
    for (let item of obj) {
      if (typeof item === "object") {
        item = this.objectToCase(item, type);
        _arrList.push(item);
      } else {
        _arrList.push(item);
      }
    }
    return _arrList;
  },
  // 显示ajax返回消息
  showMsg(res) {
    if (res.result && res.msg && res.msg.indexOf("color='red'") < 0) {
      if (res.msg)
        Message.success({
          dangerouslyUseHTMLString: true,
          message: res.msg
        });
    } else {
      if (res.msg) {
        Message.error({
          dangerouslyUseHTMLString: true,
          message: res.msg
        });
      }
    }
  },
  // 获取登录用户信息
  getUserInfo() {
    let userInfo = userInfoCookie.getUserInfo();
    if (!userInfo) userInfo = {};

    return userInfo;
  },
  // 获取登录用户信息
  getTokenInfo() {
    var tokenKey = "$tokenInfo" + store.getters.userType;
    var tokenInfo = JSON.parse(localStorage.getItem(tokenKey) || "{}");

    return tokenInfo;
  },
  // 获得对话弹出顶部高度
  getDialogTop: function() {
    var screenHeight = document.documentElement.clientHeight || document.body.clientHeight;
    return screenHeight > 900 ? "10vh" : "3vh";
  },
  loadingOject: null,
  loading: function(target, bgColor = "rgba(255, 255, 255, 0.5)") {
    if (target === false || !target) return;
    var opts = {
      text: "数据加载中",
      spinner: "el-icon-loading",
      background: bgColor,
      lock: true
    };
    if (typeof target === "object") {
      opts.target = target.$el || target;
      if (!this.loadingOject) {
        this.loadingOject = Loading.service(opts);
        this.loadingOject.loadingCount = 1;
      } else {
        this.loadingOject.loadingCount += 1;
      }
    } else {
      if (this.loadingOject) {
        this.loadingOject.loadingCount += 1;
      } else {
        opts.fullscreen = true;
        this.loadingOject = Loading.service(opts);
        this.loadingOject.loadingCount = 1;
      }
    }
  },
  loaded: function(target, bgColor = "rgba(255, 255, 255, 0.5)") {
    if (target === false || !target) return;
    var opts = {
      text: "数据加载中",
      spinner: "el-icon-loading",
      background: bgColor
    };
    if (this.loadingOject) {
      this.loadingOject.loadingCount -= 1;
      if (this.loadingOject.loadingCount === 0) {
        this.loadingOject.close();
        this.loadingOject = null;
      }
    }
  },
  // 自定义函数, 获得GUID值
  getGUID: function() {
    function GUID() {
      this.date = new Date(); /* 判断是否初始化过，如果初始化过以下代码，则以下代码将不再执行，实际中只执行一次 */
      if (typeof this.newGUID !== "function") {
        /* 生成GUID码 */
        GUID.prototype.newGUID = function() {
          this.date = new Date();
          var guidStr = "";
          var sexadecimalDate = this.hexadecimal(this.getGUIDDate(), 16);
          var sexadecimalTime = this.hexadecimal(this.getGUIDTime(), 16);
          for (var i = 0; i < 9; i++) {
            guidStr += Math.floor(Math.random() * 16).toString(16);
          }
          guidStr += sexadecimalDate;
          guidStr += sexadecimalTime;
          while (guidStr.length < 32) {
            guidStr += Math.floor(Math.random() * 16).toString(16);
          }
          return this.formatGUID(guidStr);
        };
        /* * 功能：获取当前日期的GUID格式，即8位数的日期：19700101 * 返回值：返回GUID日期格式的字条串 */
        GUID.prototype.getGUIDDate = function() {
          return this.date.getFullYear() + this.addZero(this.date.getMonth() + 1) + this.addZero(this.date.getDay());
        };
        /* * 功能：获取当前时间的GUID格式，即8位数的时间，包括毫秒，毫秒为2位数：12300933 * 返回值：返回GUID日期格式的字条串 */
        GUID.prototype.getGUIDTime = function() {
          return (
            this.addZero(this.date.getHours()) +
            this.addZero(this.date.getMinutes()) +
            this.addZero(this.date.getSeconds()) +
            this.addZero(parseInt(this.date.getMilliseconds() / 10))
          );
        };
        /* * 功能: 为一位数的正整数前面添加0，如果是可以转成非NaN数字的字符串也可以实现 * 参数: 参数表示准备再前面添加0的数字或可以转换成数字的字符串 * 返回值: 如果符合条件，返回添加0后的字条串类型，否则返回自身的字符串 */
        GUID.prototype.addZero = function(num) {
          if (Number(num).toString() !== "NaN" && num >= 0 && num < 10) {
            return "0" + Math.floor(num);
          } else {
            return num.toString();
          }
        };
        /* * 功能：将y进制的数值，转换为x进制的数值 * 参数：第1个参数表示欲转换的数值；第2个参数表示欲转换的进制；第3个参数可选，表示当前的进制数，如不写则为10 * 返回值：返回转换后的字符串 */
        GUID.prototype.hexadecimal = function(num, x, y) {
          if (y !== undefined) {
            return parseInt(num.toString(), y).toString(x);
          } else {
            return parseInt(num.toString()).toString(x);
          }
        };
        /* * 功能：格式化32位的字符串为GUID模式的字符串 * 参数：第1个参数表示32位的字符串 * 返回值：标准GUID格式的字符串 */
        GUID.prototype.formatGUID = function(guidStr) {
          var str1 = guidStr.slice(0, 8) + "-",
            str2 = guidStr.slice(8, 12) + "-",
            str3 = guidStr.slice(12, 16) + "-",
            str4 = guidStr.slice(16, 20) + "-",
            str5 = guidStr.slice(20);
          return str1 + str2 + str3 + str4 + str5;
        };
      }
    }

    var guid = new GUID();
    return guid.newGUID();
  },
  getUserGuid: function() {
    const userType = store.getters && store.getters.userType;
    const key = "$getGUID_login_" + userType;
    var guid = localStorage.getItem(key);
    if (!guid) {
      guid = userType + "-" + this.getGUID();
      localStorage.setItem(key, guid);
    }
    return guid;
  },
  getSignature: function(timeStamp, nonce, token, params) {
    var data = params && params.noDataSign ? "" : JSON.stringify(params);
    //  for (var key in params) {
    //    if (data) data += "&";
    //    data += key + '=' + params[key];
    //  }
    var guid = this.getUserGuid();

    var signStr = timeStamp + nonce + guid + (token ? token.signToken : "") + data;
    var signStr = signStr.split("").sort(function(a, b) {
      var v = a > b ? 1 : -1;
      return v;
    });
    signStr = signStr.join("");
    var signResult = md5(signStr).toUpperCase();

    return signResult;
  },
  // 外部API签名
  getSignature2: function(consignorCode, token, timeStamp, nonce, params) {
    try {
      params = JSON.parse(params);
    } catch (e) {
      Message.error("传递数据不是有效的JSON数据");
      return null;
    }
    // 去掉JSON数据的空格
    var data = JSON.stringify(params);
    console.log("json=" + data);

    var signStr = consignorCode + token + timeStamp + nonce + data;
    console.log("排序前：" + signStr);
    var signStr = signStr.split("").sort(function(a, b) {
      var v = a > b ? 1 : -1;
      return v;
    });
    signStr = signStr.join("");
    console.log("排序后：" + signStr);
    var signResult = md5(signStr).toUpperCase();

    return signResult;
  },
  // 日期时间格式化
  formatDate: function(date, pattern) {
    if (!date) return "";
    if (!pattern) pattern = "YYYY-MM-DD";
    pattern = pattern.replace("yyyy-MM-dd", "YYYY-MM-DD");
    if (typeof date === "string" && date && date.indexOf("T") > 0) {
      date = new Date(date);
    } else if (typeof date === "number") date = new Date(date);

    return moment(date).format(pattern);
  },
  // 数字格式化
  formatNumber: function(number, pattern, zeroExc, col) {
    try {
      if (isNaN(number)) {
        return null;
      } else if (number === undefined || number === null) {
        return null;
      }

      if (pattern === "%") {
        return Math.Round(Number(number) * 100, 2) + "%";
      }

      function _format(pattern, num, z) {
        var j = pattern.length >= num.length ? pattern.length : num.length;
        var p = pattern.split("");
        var n = num.split("");
        var bool = true,
          nn = "";
        for (var i = 0; i < j; i++) {
          var x = n[n.length - j + i];
          var y = p[p.length - j + i];
          if (z === 0) {
            if (bool) {
              if ((x && y && (x !== "0" || y === "0")) || (x && x !== "0" && !y) || (y && y === "0" && !x)) {
                nn += x ? x : "0";
                bool = false;
              }
            } else {
              nn += x ? x : "0";
            }
          } else {
            if (y && (y === "0" || (y === "#" && x))) nn += x ? x : "0";
          }
        }
        return nn;
      }

      function _formatNumber(numChar, pattern) {
        var patterns = pattern.split(".");
        var numChars = numChar.split(".");
        var z = patterns[0].indexOf(",") === -1 ? -1 : patterns[0].length - patterns[0].indexOf(",");
        var num1 = _format(patterns[0].replace(",", ""), numChars[0], 0);
        var num2 = _format(
          patterns[1]
            ? patterns[1]
                .split("")
                .reverse()
                .join("")
            : "",
          numChars[1]
            ? numChars[1]
                .split("")
                .reverse()
                .join("")
            : "",
          1
        );
        num1 = num1
          .split("")
          .reverse()
          .join("");
        var reCat = eval("/[0-9]{" + (z - 1) + "," + (z - 1) + "}/gi");
        var arrdata = z > -1 ? num1.match(reCat) : undefined;
        if (arrdata && arrdata.length > 0) {
          var w = num1.replace(arrdata.join(""), "");
          num1 = arrdata.join(",") + (w === "" ? "" : ",") + w;
        }
        num1 = num1
          .split("")
          .reverse()
          .join("");
        return (
          (num1 === "" ? "0" : num1) +
          (num2 !== ""
            ? "." +
              num2
                .split("")
                .reverse()
                .join("")
            : "")
        );
      }

      pattern = pattern || "#.##";
      zeroExc = zeroExc || true;
      if (pattern.indexOf(".") >= 0) {
        // 小数长度
        let fractionalLength = pattern.split(".")[1].length;
        number = Math.Round(number, fractionalLength);
      }

      var reCat = /[0#,.]{1,}/gi;
      var _pattern = pattern.match(reCat)[0];
      var numChar = number.toString();
      return !(zeroExc && numChar === 0) ? pattern.replace(_pattern, _formatNumber(numChar, _pattern)) : pattern.replace(_pattern, "0");
    } catch (error) {
      return number;
    }
  },
  // 判断字符串是否为数字
  isNumber(val) {
    var regPos = /^\d+(\.\d+)?$/; // 非负浮点数
    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; // 负浮点数
    if (regPos.test(val) || regNeg.test(val)) {
      return true;
    } else {
      return false;
    }
  },
  /**
   * 判断当前值是否为对象
   * @param {*} obj 需要判断的对象
   */
  isObject: function(obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
  },
  /**
   * 判断当前值是否为空对象
   * @param {*} obj 需要判断的对象
   */
  isEmptyObject: function(obj) {
    if (!this.isObject(obj)) return false;

    for (var key in obj) {
      return false;
    }
    return true;
  },
  // 下拉框数据缓存
  dropdownData: {},
  // 加载下拉框数据
  loadDropDown(ddIDs, dataOptions) {
    if (!Array.isArray(ddIDs)) {
      ddIDs = ddIDs.split(",");
    }
    // 过滤掉已经加载的下拉框，不要重复加载了
    ddIDs = ddIDs.filter(id => {
      return !this.dropdownData["dropdown" + id];
    });
    if (!ddIDs.length) return;

    var url = "/api/common/loadDropDown";
    var params = Object.assign({}, dataOptions, {
      where: ddIDs.join(",")
    });
    this.ajax(
      url,
      params,
      res => {
        this.showMsg(res);
        if (res.result) {
          this.dropdownData = Object.assign({}, this.dropdownData, res.data);
        }
      },
      false
    );
  },
  // 翻译下拉框值
  translateText(val, dropdown_Id) {
    var ddList = this.dropdownData["dropdown" + dropdown_Id];
    if (!ddList) return val;
    var item = ddList.find((item, index, arr) => {
      return item.value === val;
    });
    if (!item) return val;
    return item.label;
  },
  // SaaS权限
  hasSaaSAuth(name, ref) {
    const url = "/api/auth/hasSaasAuth";
    const params = {
      IDs: name
    };
    this.ajax(url, params, res => {
      let result = res.result;
      if (result) {
        result = res.data.some(item => {
          return item.moduleName === name;
        });
      }
      if (!result) {
        res.msg = "当前模块没有权限操作";
        window.setTimeout(() => {
          ref.classList.add("no-auth");
        }, 1000);
      }
      this.showMsg(res);
    });
  },
  // SaaS权限 - 按钮权限
  isSaaSAuth(nameList, callback) {
    const url = "/api/auth/hasSaasAuth";
    const params = {
      IDs: nameList.join(",")
    };
    this.ajax(url, params, res => {
      if (typeof callback === "function") {
        callback(res);
      }
    });
  },
  // 深度复制
  deepCopy(dataSource) {
    if (!dataSource) return null;

    let data = JSON.stringify(dataSource);
    return JSON.parse(data);
  },
  // NaN显示处理
  isNaN(val) {
    if (val || val === 0) {
      return val;
    } else if (val === null) {
      return "";
    } else {
      return isNaN(val) ? "" : val;
    }
  },
  // 通用tag背景颜色
  getTagBgColor(field, value) {
    const tagColorList = field.tagColorList;
    const colorItem = tagColorList.find(item => {
      if (item.operator === "=" && item.value === value) {
        return true;
      } else if (item.operator === ">=" && item.value >= value) {
        return true;
      } else if (item.operator === "模糊" && value.indexOf(value) >= 0) {
        return true;
      } else {
        return false;
      }
    });
    let bgColor = "#fff";
    if (colorItem) bgColor = colorItem.backgroudColor;

    return bgColor;
  },
  // 通用tag字体颜色
  getTagColor(field, value) {
    const tagColorList = field.tagColorList;
    const colorItem = tagColorList.find(item => {
      if (item.operator === "=" && item.value === value) {
        return true;
      } else if (item.operator === ">=" && item.value >= value) {
        return true;
      } else if (item.operator === "模糊" && value.indexOf(value) >= 0) {
        return true;
      } else {
        return false;
      }
    });
    let color = "#000";
    if (colorItem) color = colorItem.color;

    return {
      border: 0,
      color: color
    };
  },
  // 格式化数据
  formatData(row, col) {
    let data = "";
    if (["date", "datetime"].indexOf(col.dataType) >= 0 && col.formatter) {
      data = this.formatDate(row[col.prop], col.formatter);
    } else if (["byte", "int32", "int64", "decimal", "double"].indexOf(col.dataType) >= 0 && col.formatter) {
      data = this.formatNumber(row[col.prop], col.formatter);
    } else {
      data = row[col.prop];
    }
    try {
      if (col.script) {
        // eslint-disable-next-line
        eval(col.script);
      }
    } catch (error) {
      this.$message.error(`${col.label}脚本执行错误：${error.messsage}`);
    }
    return data;
  }
};
