/**
 * 整体项目设置相关的 store
 */

import Cookies from "js-cookie";
import { setTheme } from "@/utils";
function toJSON(str) {
  if (typeof str === "string") {
    try {
      var obj = JSON.parse(str);
      if (typeof obj === "object" && obj) {
        return obj;
      } else {
        return {};
      }
    } catch (e) {
      console.log("toJSON error：" + str + "!!!" + e);
      return {};
    }
  }
  console.log("It is not a string!");
}

const app = {
  state: {
    sidebar: {
      opened: Cookies.get("sidebarStatus") ? !!+Cookies.get("sidebarStatus") : true,
      withoutAnimation: false
    },
    device: "desktop",
    drawer: false,
    themeColor: Cookies.get("themeColor") || "",
    theme: Cookies.get("theme") || "blackgold",
    // themePicker: '',
    sex: [
      { value: false, label: "女" },
      { value: true, label: "男" }
    ],
    language: Cookies.get("language") || "zh",
    size: Cookies.get("size") || "medium",
    basicInfo: toJSON(Cookies.get("basicInfo")) // 系统信息
  },
  mutations: {
    TOGGLE_SIDEBAR: state => {
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;
      if (state.sidebar.opened) {
        Cookies.set("sidebarStatus", 1);
      } else {
        Cookies.set("sidebarStatus", 0);
      }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      Cookies.set("sidebarStatus", 0);
      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device;
    },
    TOGGLE_DRAWER: (state, opened = true) => {
      state.drawer = opened;
    },
    CHANGE_THEME: (state, theme = state.theme) => {
      Cookies.set("theme", theme);
      state.theme = theme;
      setTheme(theme);
    },
    CHANGE_THEME_COLOR: (state, color) => {
      Cookies.set("themeColor", color);
      state.themeColor = color;
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      Cookies.set("language", language);
    },
    SET_SIZE: (state, size) => {
      state.size = size;
      Cookies.set("size", size);
    },
    SET_BasicInfo: (state, basicInfo) => {
      state.basicInfo = basicInfo;
      Cookies.set("basicInfo", JSON.stringify(basicInfo));
    }
  },
  actions: {
    toggleSideBar({ commit }) {
      commit("TOGGLE_SIDEBAR");
    },
    closeSideBar({ commit }, { withoutAnimation }) {
      commit("CLOSE_SIDEBAR", withoutAnimation);
    },
    toggleDevice({ commit }, device) {
      commit("TOGGLE_DEVICE", device);
    },
    toggleDrawer({ commit }, opened) {
      commit("TOGGLE_DRAWER", opened);
    },
    changeTheme({ commit }, { theme }) {
      return commit("CHANGE_THEME", theme);
    },
    changeThemeColor({ commit }, color) {
      return commit("CHANGE_THEME_COLOR", color);
    },
    setLanguage({ commit }, language) {
      commit("SET_LANGUAGE", language);
    },
    setSize({ commit }, size) {
      commit("SET_SIZE", size);
    },
    setBasicInfo({ commit }, basicInfo) {
      commit("SET_BasicInfo", basicInfo);
    }
  }
};

export default app;
