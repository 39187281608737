/* eslint-disable */
export const asyncRouterMap = [{
	path: '/bigData',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'big-data',
	alwaysShow: true,
	meta: {
		title: 'big-data',
		icon: 'menu-icon el-icon-yrt-kehufenpei',
		menu_Id: 1554,
    menuName: '大数据',
    isOpenWindow: false
	},
	children:[{
		path: '/bigData/storage-performance',
		component: () => import('@/views/bigData/storage-performance'),
		name: 'bigData-storage-performance',
		meta: {
			title: 'bigData-storage-performance',
			icon: '',
			menu_Id: 1587,
			menuName: '工作绩效表',
      isOpenWindow: true
		}
	},{
		path: '/bigData/storage-ai',
		component: () => import('@/views/bigData/storage-ai'),
		name: 'bigData-storage-ai',
		meta: {
			title: 'bigData-storage-ai',
			icon: '',
			menu_Id: 1589,
			menuName: '智能仓配数据平台',
      isOpenWindow: true
		}
	},{
		path: '/bigData/storage-progress',
		component: () => import('@/views/bigData/storage-progress'),
		name: 'bigData-storage-progress',
		meta: {
			title: 'bigData-storage-progress',
			icon: '',
			menu_Id: 1590,
			menuName: '仓储岗位进度表',
      isOpenWindow: true
		}
	},{
		path: '/bigData/tms-runtime',
		component: () => import('@/views/bigData/tms-runtime'),
		name: 'bigData-tms-runtime',
		meta: {
			title: 'bigData-tms-runtime',
			icon: '',
			menu_Id: 1591,
			menuName: '物流实时监控',
      isOpenWindow: true
		}
	},{
		path: '/bigData/tms-playback',
		component: () => import('@/views/bigData/tms-playback'),
		name: 'bigData-tms-playback',
		meta: {
			title: 'bigData-tms-playback',
			icon: '',
			menu_Id: 1593,
			menuName: '物流轨迹回放',
      isOpenWindow: true
		}
	}]
},{
	path: '/inbound',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'inbound',
	alwaysShow: true,
	meta: {
		title: 'inbound',
		icon: 'menu-icon el-icon-yrt-ruku1',
		menu_Id: 101,
    menuName: '收货',
    isOpenWindow: false
	},
	children:[{
	path: 'purchase',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'purchase-base',
	alwaysShow: true,
	meta: {
		title: 'purchase-base',
		icon: '',
		menu_Id: 212,
    menuName: '收货单据',
    isOpenWindow: false
	},
	children:[{
		path: '/inbound/purchase/quotation',
		component: () => import('@/views/inbound/purchase/quotation'),
		name: 'inbound-purchase-quotation',
		meta: {
			title: 'inbound-purchase-quotation',
			icon: '',
			menu_Id: 301,
			menuName: '入库计划单',
      isOpenWindow: false
		}
	},{
		path: 'damaged-order',
		component: () => import('@/views/inbound/purchase/damaged-order'),
		name: 'inbound-purchase-damaged-order',
		meta: {
			title: 'inbound-purchase-damaged-order',
			icon: '',
			menu_Id: 1603,
			menuName: '残品入库单',
      isOpenWindow: false
		}
	},{
		path: 'order',
		component: () => import('@/views/inbound/purchase/order'),
		name: 'inbound-purchase-order',
		meta: {
			title: 'inbound-purchase-order',
			icon: '',
			menu_Id: 102,
			menuName: '预到货单',
      isOpenWindow: false
		}
	},{
		path: '/outbound/stat/orderList-lack',
		component: () => import('@/views/outbound/stat/orderList-lack'),
		name: 'outbound-stat-orderList-lack',
		meta: {
			title: 'outbound-stat-orderList-lack',
			icon: '',
			menu_Id: 1803,
			menuName: '缺货转预到货',
      isOpenWindow: false
		}
	},{
		path: '/inbound/purchase/adviseqty',
		component: () => import('@/views/inbound/purchase/adviseqty'),
		name: 'inbound-purchase-adviseqty',
		meta: {
			title: 'inbound-purchase-adviseqty',
			icon: '',
			menu_Id: 1592,
			menuName: '建议采购转预到货',
      isOpenWindow: false
		}
	},{
		path: '/inbound/import/quotation',
		component: () => import('@/views/inbound/import/quotation'),
		name: 'inbound-import-quotation',
		meta: {
			title: 'inbound-import-quotation',
			icon: '',
			menu_Id: 200,
			menuName: '入库计划批量导入',
      isOpenWindow: false
		}
	},{
		path: 'purchase-order-import',
		component: () => import('@/views/inbound/import/purchase-order'),
		name: 'inbound-import-purchase-order',
		meta: {
			title: 'inbound-import-purchase-order',
			icon: '',
			menu_Id: 265,
			menuName: '预到货批量导入',
      isOpenWindow: false
		}
	}]
},{
	path: 'scan-enter',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'scan-enter',
	alwaysShow: true,
	meta: {
		title: 'scan-enter',
		icon: '',
		menu_Id: 313,
    menuName: '收货作业',
    isOpenWindow: false
	},
	children:[{
		path: '/inbound/check/qualityCheck',
		component: () => import('@/views/inbound/check/qualityCheck'),
		name: '/inbound/scan/qualityCheck',
		meta: {
			title: '/inbound/scan/qualityCheck',
			icon: '',
			menu_Id: 1519,
			menuName: '质检管理',
      isOpenWindow: false
		}
	},{
		path: '/inbound/check/arrivalProcess',
		component: () => import('@/views/inbound/check/arrivalProcess'),
		name: '/inbound/check/qualityarrivalProcess',
		meta: {
			title: '/inbound/check/qualityarrivalProcess',
			icon: '',
			menu_Id: 1520,
			menuName: '到货加工管理',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/order',
		component: () => import('@/views/inbound/scan/order'),
		name: 'inbound-scan-order',
		meta: {
			title: 'inbound-scan-order',
			icon: '',
			menu_Id: 1502,
			menuName: '按单扫描入库',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/order-case',
		component: () => import('@/views/inbound/scan/order-case'),
		name: 'inbound-scan-order-case',
		meta: {
			title: 'inbound-scan-order-case',
			icon: '',
			menu_Id: 466,
			menuName: '装箱收货入库',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/inLpn',
		component: () => import('@/views/inbound/scan/inLpn'),
		name: '/inbound/scan/inLpn',
		meta: {
			title: '/inbound/scan/inLpn',
			icon: '',
			menu_Id: 1724,
			menuName: 'LPN号扫描入库',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/inPai',
		component: () => import('@/views/inbound/scan/inPai'),
		name: '/inbound/scan/inPai',
		meta: {
			title: '/inbound/scan/inPai',
			icon: '',
			menu_Id: 180,
			menuName: '按拍扫描入库',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/order-pai-scan',
		component: () => import('@/views/inbound/scan/order-pai-scan'),
		name: 'inbound-scan-order-pai-scan',
		meta: {
			title: 'inbound-scan-order-pai-scan',
			icon: '',
			menu_Id: 1685,
			menuName: '按单码盘扫描',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/noBillscan',
		component: () => import('@/views/inbound/scan/noBillscan'),
		name: 'inbound-scan-noBillscan',
		meta: {
			title: 'inbound-scan-noBillscan',
			icon: '',
			menu_Id: 219,
			menuName: '无单扫描入库',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/order-light',
		component: () => import('@/views/inbound/scan/order-light'),
		name: 'inbound-scan-order-light',
		meta: {
			title: 'inbound-scan-order-light',
			icon: '',
			menu_Id: 345,
			menuName: '灯光分拣入库',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/enter-stacking-scan',
		component: () => import('@/views/inbound/scan/enter-stacking-scan'),
		name: 'inbound-scan-enter-stacking-scan',
		meta: {
			title: 'inbound-scan-enter-stacking-scan',
			icon: '',
			menu_Id: 125,
			menuName: '码盘扫描入库',
      isOpenWindow: false
		}
	},{
		path: '/inbound/purchase/enter',
		component: () => import('@/views/inbound/purchase/enter'),
		name: 'inbound-purchase-enter',
		meta: {
			title: 'inbound-purchase-enter',
			icon: '',
			menu_Id: 103,
			menuName: '入库记录查询',
      isOpenWindow: false
		}
	}]
},{
	path: 'scan-shelve',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'scan-shelve',
	alwaysShow: true,
	meta: {
		title: 'scan-shelve',
		icon: '',
		menu_Id: 124,
    menuName: '上架作业',
    isOpenWindow: false
	},
	children:[{
		path: '/inbound/purchase/create-shelve',
		component: () => import('@/views/inbound/purchase/create-shelve'),
		name: 'create-shelve',
		meta: {
			title: 'create-shelve',
			icon: '',
			menu_Id: 479,
			menuName: '生成上架单',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/shelveLpn',
		component: () => import('@/views/inbound/scan/shelveLpn'),
		name: '/inbound/scan/shelveLpn',
		meta: {
			title: '/inbound/scan/shelveLpn',
			icon: '',
			menu_Id: 1725,
			menuName: 'LPN号扫描上架',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/shelve-order',
		component: () => import('@/views/inbound/scan/shelve-order'),
		name: 'inbound-scan-shelve-order',
		meta: {
			title: 'inbound-scan-shelve-order',
			icon: '',
			menu_Id: 223,
			menuName: '待上架单扫描',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/shelve-stacking-scan',
		component: () => import('@/views/inbound/scan/shelve-stacking-scan'),
		name: 'inbound-scan-shelve-stacking-scan',
		meta: {
			title: 'inbound-scan-shelve-stacking-scan',
			icon: '',
			menu_Id: 199,
			menuName: '上架码盘扫描',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/shelvePai',
		component: () => import('@/views/inbound/scan/shelvePai'),
		name: '/inbound/scan/shelvePai',
		meta: {
			title: '/inbound/scan/shelvePai',
			icon: '',
			menu_Id: 184,
			menuName: '按拍扫描上架',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/shelve-nobill-scan',
		component: () => import('@/views/inbound/scan/shelve-stacking-scan'),
		name: 'inbound-scan-shelve-nobill-scan',
		meta: {
			title: 'inbound-scan-shelve-nobill-scan',
			icon: '',
			menu_Id: 218,
			menuName: '无单扫描上架',
      isOpenWindow: false
		}
	},{
		path: '/inbound/purchase/shelve',
		component: () => import('@/views/inbound/purchase/shelve'),
		name: 'inbound-purchase-shelve',
		meta: {
			title: 'inbound-purchase-shelve',
			icon: '',
			menu_Id: 269,
			menuName: '上架记录查询',
      isOpenWindow: false
		}
	}]
},{
	path: 'return',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'return-base',
	alwaysShow: true,
	meta: {
		title: 'return-base',
		icon: '',
		menu_Id: 314,
    menuName: '到货退换货',
    isOpenWindow: false
	},
	children:[{
		path: '/inbound/purchase/return',
		component: () => import('@/views/inbound/purchase/return'),
		name: 'inbound-purchase-return',
		meta: {
			title: 'inbound-purchase-return',
			icon: '',
			menu_Id: 104,
			menuName: '到货退货单',
      isOpenWindow: false
		}
	},{
		path: '/inbound/purchase/exchange',
		component: () => import('@/views/inbound/purchase/exchange'),
		name: 'inbound-purchase-exchange',
		meta: {
			title: 'inbound-purchase-exchange',
			icon: '',
			menu_Id: 105,
			menuName: '到货换货单',
      isOpenWindow: false
		}
	}]
}]
},{
	path: '/outbound',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'outbound',
	alwaysShow: true,
	meta: {
		title: 'outbound',
		icon: 'menu-icon el-icon-yrt-chuku1',
		menu_Id: 110,
    menuName: '出库',
    isOpenWindow: false
	},
	children:[{
	path: 'sale',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'sale-base',
	alwaysShow: true,
	meta: {
		title: 'sale-base',
		icon: '',
		menu_Id: 316,
    menuName: '出库单据',
    isOpenWindow: false
	},
	children:[{
		path: '/outbound/order/retail',
		component: () => import('@/views/outbound/order/retail'),
		name: 'outbound-order-retail',
		meta: {
			title: 'outbound-order-retail',
			icon: '',
			menu_Id: 247,
			menuName: '出库订货单',
      isOpenWindow: false
		}
	},{
		path: '/outbound/order/retail-check',
		component: () => import('@/views/outbound/order/retail-check'),
		name: 'outbound-order-retail-check',
		meta: {
			title: 'outbound-order-retail-check',
			icon: '',
			menu_Id: 1640,
			menuName: '订货单验收',
      isOpenWindow: false
		}
	},{
		path: '/outbound/sale/order-plan',
		component: () => import('@/views/outbound/sale/order-plan'),
		name: 'outbound-sale-order-plan',
		meta: {
			title: 'outbound-sale-order-plan',
			icon: '',
			menu_Id: 302,
			menuName: '出库计划单',
      isOpenWindow: false
		}
	},{
		path: 'order',
		component: () => import('@/views/outbound/sale/order'),
		name: 'outbound-sale-order',
		meta: {
			title: 'outbound-sale-order',
			icon: '',
			menu_Id: 112,
			menuName: '出库订单',
      isOpenWindow: false
		}
	},{
		path: '/outbound/order/place-order-type',
		component: () => import('@/views/outbound/order/place-order-type'),
		name: 'outbound-order-place-order-type',
		meta: {
			title: 'outbound-order-place-order-type',
			icon: '',
			menu_Id: 1580,
			menuName: '下单类别管理',
      isOpenWindow: false
		}
	}]
},{
	path: 'operation',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'operation-out',
	alwaysShow: true,
	meta: {
		title: 'operation-out',
		icon: '',
		menu_Id: 317,
    menuName: '常规出库操作',
    isOpenWindow: false
	},
	children:[{
		path: 'create-order-batch',
		component: () => import('@/views/outbound/operation/create-order-batch'),
		name: 'outbound-operation-create-order-batch',
		meta: {
			title: 'outbound-operation-create-order-batch',
			icon: '',
			menu_Id: 380,
			menuName: '生成波次',
      isOpenWindow: false
		}
	},{
		path: 'order-batch',
		component: () => import('@/views/outbound/operation/order-batch'),
		name: 'outbound-operation-order-batch',
		meta: {
			title: 'outbound-operation-order-batch',
			icon: '',
			menu_Id: 248,
			menuName: '波次查询',
      isOpenWindow: false
		}
	},{
		path: 'order-printer',
		component: () => import('@/views/outbound/operation/order-printer'),
		name: 'outbound-order-printer',
		meta: {
			title: 'outbound-order-printer',
			icon: '',
			menu_Id: 491,
			menuName: '单据补打',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/out-light',
		component: () => import('@/views/outbound/scan/out-light'),
		name: 'outbound-scan-out-light',
		meta: {
			title: 'outbound-scan-out-light',
			icon: '',
			menu_Id: 1735,
			menuName: '灯光校验出库',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/order-picking',
		component: () => import('@/views/outbound/scan/order-picking'),
		name: 'outbound-scan-order-picking',
		meta: {
			title: 'outbound-scan-order-picking',
			icon: '',
			menu_Id: 249,
			menuName: '出库拣货下架',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/order-plate-picking',
		component: () => import('@/views/outbound/scan/order-plate-picking'),
		name: 'outbound-scan-order-plate-picking',
		meta: {
			title: 'outbound-scan-order-plate-picking',
			icon: '',
			menu_Id: 1643,
			menuName: '出库按拍下架',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/order-picking-shelve',
		component: () => import('@/views/outbound/scan/order-picking-shelve'),
		name: 'outbound-scan-order-picking-shelve',
		meta: {
			title: 'outbound-scan-order-picking-shelve',
			icon: '',
			menu_Id: 129,
			menuName: '出库下架回拣',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/order-matching',
		component: () => import('@/views/outbound/scan/order-matching'),
		name: 'outbound-scan-order-matching',
		meta: {
			title: 'outbound-scan-order-matching',
			icon: '',
			menu_Id: 250,
			menuName: '出库单常规配货',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/order-matching-light',
		component: () => import('@/views/outbound/scan/order-matching-light'),
		name: 'outbound-scan-order-matching-light',
		meta: {
			title: 'outbound-scan-order-matching-light',
			icon: '',
			menu_Id: 469,
			menuName: '出库单灯光配货',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/out',
		component: () => import('@/views/outbound/scan/out'),
		name: 'outbound-scan-out',
		meta: {
			title: 'outbound-scan-out',
			icon: '',
			menu_Id: 113,
			menuName: '出库单打包校验',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/lineOut',
		component: () => import('@/views/outbound/scan/lineOut'),
		name: 'outbound-scan-line-out',
		meta: {
			title: 'outbound-scan-line-out',
			icon: '',
			menu_Id: 1836,
			menuName: '线路打包校验',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/out-scan-batch',
		component: () => import('@/views/outbound/scan/out-scan-batch'),
		name: 'outbound-scan-out-scan-batch',
		meta: {
			title: 'outbound-scan-out-scan-batch',
			icon: '',
			menu_Id: 217,
			menuName: '波次打包校验',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/peiout',
		component: () => import('@/views/outbound/scan/peiout'),
		name: 'outbound-scan-peiout',
		meta: {
			title: 'outbound-scan-peiout',
			icon: '',
			menu_Id: 1814,
			menuName: '配货打包校验',
      isOpenWindow: false
		}
	},{
		path: '/outbound/sale/outer',
		component: () => import('@/views/outbound/sale/outer'),
		name: 'outbound-sale-outer',
		meta: {
			title: 'outbound-sale-outer',
			icon: '',
			menu_Id: 268,
			menuName: '打包校验查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/outer-everyDayStatistics',
		component: () => import('@/views/stat/out/outer-everyDayStatistics'),
		name: 'outer-everyDayStatistics',
		meta: {
			title: 'outer-everyDayStatistics',
			icon: '',
			menu_Id: 1869,
			menuName: '每日打包统计',
      isOpenWindow: false
		}
	}]
},{
	path: 'operation-tms',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'operation-tms',
	alwaysShow: true,
	meta: {
		title: 'operation-tms',
		icon: '',
		menu_Id: 1835,
    menuName: '装车出库操作',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/plan/create-plan',
		component: () => import('@/views/tms/plan/create-plan'),
		name: 'tms-plan-create-plan',
		meta: {
			title: 'tms-plan-create-plan',
			icon: '',
			menu_Id: 1624,
			menuName: '生成装车计划单',
      isOpenWindow: false
		}
	},{
		path: '/tms/plan/load-plan',
		component: () => import('@/views/tms/plan/load-plan'),
		name: 'tms-load-plan',
		meta: {
			title: 'tms-load-plan',
			icon: '',
			menu_Id: 1631,
			menuName: '装车计划单查询',
      isOpenWindow: false
		}
	}]
},{
	path: 'send',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'send-base',
	alwaysShow: true,
	meta: {
		title: 'send-base',
		icon: '',
		menu_Id: 1506,
    menuName: '发货操作',
    isOpenWindow: false
	},
	children:[{
		path: '/outbound/scan/order-send',
		component: () => import('@/views/outbound/scan/order-send'),
		name: 'outbound-scan-order-send',
		meta: {
			title: 'outbound-scan-order-send',
			icon: '',
			menu_Id: 326,
			menuName: '发货校验',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/order-send-fast',
		component: () => import('@/views/outbound/scan/order-send-fast'),
		name: 'outbound-scan-order-send-fast',
		meta: {
			title: 'outbound-scan-order-send-fast',
			icon: '',
			menu_Id: 425,
			menuName: '快速发货校验',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/order-send-batch',
		component: () => import('@/views/outbound/scan/order-send-batch'),
		name: 'outbound-scan-order-send-batch',
		meta: {
			title: 'outbound-scan-order-send-batch',
			icon: '',
			menu_Id: 1508,
			menuName: '闪电发货校验',
      isOpenWindow: false
		}
	},{
		path: '/outbound/sale/sendlist',
		component: () => import('@/views/outbound/sale/sendlist'),
		name: 'outbound-sale-sendlist',
		meta: {
			title: 'outbound-sale-sendlist',
			icon: '',
			menu_Id: 325,
			menuName: '发货历史记录',
      isOpenWindow: false
		}
	},{
		path: '/outbound/manufacture/orderMatching',
		component: () => import('@/views/outbound/manufacture/orderMatching'),
		name: 'outbound-manufacture-orderMatching',
		meta: {
			title: 'outbound-manufacture-orderMatching',
			icon: '',
			menu_Id: 119,
			menuName: '配货历史记录',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/outWeight-import',
		component: () => import('@/views/outbound/scan/outWeight-import'),
		name: 'outbound-scan-outWeight-import',
		meta: {
			title: 'outbound-scan-outWeight-import',
			icon: '',
			menu_Id: 1761,
			menuName: '发货重量导入',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/outInformat-import',
		component: () => import('@/views/outbound/scan/outInformat-import'),
		name: 'outbound-scan-outInformat-import',
		meta: {
			title: 'outbound-scan-outInformat-import',
			icon: '',
			menu_Id: 315,
			menuName: '发货信息导入',
      isOpenWindow: false
		}
	}]
},{
	path: 'sale-other',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'sale-other',
	alwaysShow: true,
	meta: {
		title: 'sale-other',
		icon: '',
		menu_Id: 1586,
    menuName: '出库其他业务',
    isOpenWindow: false
	},
	children:[{
		path: '/outbound/require/goods',
		component: () => import('@/views/outbound/require/goods'),
		name: 'outbound-require-goods',
		meta: {
			title: 'outbound-require-goods',
			icon: '',
			menu_Id: 1763,
			menuName: '要货管理',
      isOpenWindow: false
		}
	},{
		path: '/outbound/require/allocation',
		component: () => import('@/views/outbound/require/allocation'),
		name: 'outbound-require-allocation',
		meta: {
			title: 'outbound-require-allocation',
			icon: '',
			menu_Id: 1742,
			menuName: '调拨单管理',
      isOpenWindow: false
		}
	},{
		path: '/outbound/require/voucher',
		component: () => import('@/views/outbound/require/voucher'),
		name: 'outbound-require-voucher',
		meta: {
			title: 'outbound-require-voucher',
			icon: '',
			menu_Id: 1625,
			menuName: '客户购物券',
      isOpenWindow: false
		}
	}]
},{
	path: 'service-base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'service-base',
	alwaysShow: true,
	meta: {
		title: 'service-base',
		icon: '',
		menu_Id: 318,
    menuName: '售后操作',
    isOpenWindow: false
	},
	children:[{
		path: '/outbound/service/return',
		component: () => import('@/views/outbound/service/return'),
		name: 'outbound-service-return',
		meta: {
			title: 'outbound-service-return',
			icon: '',
			menu_Id: 114,
			menuName: '出库退货单',
      isOpenWindow: false
		}
	},{
		path: '/outbound/service/exchange',
		component: () => import('@/views/outbound/service/exchange'),
		name: 'outbound-service-exchange',
		meta: {
			title: 'outbound-service-exchange',
			icon: '',
			menu_Id: 115,
			menuName: '出库换货单',
      isOpenWindow: false
		}
	},{
		path: '/outbound/service/return-apply',
		component: () => import('@/views/outbound/service/return-apply'),
		name: 'outbound-service-return-apply',
		meta: {
			title: 'outbound-service-return-apply',
			icon: '',
			menu_Id: 1581,
			menuName: '客户售后单',
      isOpenWindow: false
		}
	}]
},{
	path: 'sale-his',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'sale-his',
	alwaysShow: true,
	meta: {
		title: 'sale-his',
		icon: '',
		menu_Id: 339,
    menuName: '历史单据',
    isOpenWindow: false
	},
	children:[{
		path: '/outbound/sale/orderPicking',
		component: () => import('@/views/outbound/sale/orderPicking'),
		name: 'outbound-sale-orderPicking',
		meta: {
			title: 'outbound-sale-orderPicking',
			icon: '',
			menu_Id: 116,
			menuName: '拣货下架记录',
      isOpenWindow: false
		}
	}]
}]
},{
	path: '/storage',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'storage',
	alwaysShow: true,
	meta: {
		title: 'storage',
		icon: 'menu-icon el-icon-yrt-kucunchaxun',
		menu_Id: 135,
    menuName: '仓储',
    isOpenWindow: false
	},
	children:[{
	path: 'base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'storage-base',
	alwaysShow: true,
	meta: {
		title: 'storage-base',
		icon: '',
		menu_Id: 154,
    menuName: '库存状况',
    isOpenWindow: false
	},
	children:[{
		path: '/storage/base/product-position',
		component: () => import('@/views/storage/base/product-position'),
		name: 'storage-base-product-position',
		meta: {
			title: 'storage-base-product-position',
			icon: '',
			menu_Id: 385,
			menuName: '库存明细查询',
      isOpenWindow: false
		}
	},{
		path: '/storage/base/product-position-plate',
		component: () => import('@/views/storage/base/product-position-plate'),
		name: 'storage-base-product-position-plate',
		meta: {
			title: 'storage-base-product-position-plate',
			icon: '',
			menu_Id: 1851,
			menuName: '托盘号库存查询',
      isOpenWindow: false
		}
	},{
		path: '/storage/base/product-position-spu',
		component: () => import('@/views/storage/base/product-position-spu'),
		name: 'storage-base-product-position-spu',
		meta: {
			title: 'storage-base-product-position-spu',
			icon: '',
			menu_Id: 1853,
			menuName: 'SPU库存查询',
      isOpenWindow: false
		}
	},{
		path: '/storage/base/product-position-sn',
		component: () => import('@/views/storage/base/product-position-sn'),
		name: 'storage-base-product-position-sn',
		meta: {
			title: 'storage-base-product-position-sn',
			icon: '',
			menu_Id: 1837,
			menuName: '库存SN查询',
      isOpenWindow: false
		}
	},{
		path: '/storage/base/product-position-group',
		component: () => import('@/views/storage/base/product-position-group'),
		name: 'storage-base-product-position-group',
		meta: {
			title: 'storage-base-product-position-group',
			icon: '',
			menu_Id: 155,
			menuName: '商品库存查询',
      isOpenWindow: false
		}
	},{
		path: '/storage/base/product-position-name',
		component: () => import('@/views/storage/base/product-position-name'),
		name: 'storage-base-product-position-name',
		meta: {
			title: 'storage-base-product-position-name',
			icon: '',
			menu_Id: 145,
			menuName: '货位库存查询',
      isOpenWindow: false
		}
	},{
		path: '/storage/base/product-position-history',
		component: () => import('@/views/storage/base/product-position-history'),
		name: 'storage-base-product-position-history',
		meta: {
			title: 'storage-base-product-position-history',
			icon: '',
			menu_Id: 271,
			menuName: '库存监测记录',
      isOpenWindow: false
		}
	},{
		path: '/storage/base/product-placeholder',
		component: () => import('@/views/storage/base/product-placeholder'),
		name: 'storage-base-product-placeholder',
		meta: {
			title: 'storage-base-product-placeholder',
			icon: '',
			menu_Id: 272,
			menuName: '库存占位查询',
      isOpenWindow: false
		}
	},{
		path: '/storage/setting/position-viewer',
		component: () => import('@/views/storage/setting/position-viewer'),
		name: 'storage-setting-position-viewer',
		meta: {
			title: 'storage-setting-position-viewer',
			icon: '',
			menu_Id: 1546,
			menuName: '库存可视化查询',
      isOpenWindow: false
		}
	},{
		path: '/storage/setting/vstorage-area',
		component: () => import('@/views/storage/setting/vstorage-area'),
		name: 'storage-setting-vstorage-area',
		meta: {
			title: 'storage-setting-vstorage-area',
			icon: '',
			menu_Id: 1608,
			menuName: '库容实时查询',
      isOpenWindow: false
		}
	}]
},{
	path: 'operation',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'operation',
	alwaysShow: true,
	meta: {
		title: 'operation',
		icon: '',
		menu_Id: 117,
    menuName: '库存操作',
    isOpenWindow: false
	},
	children:[{
		path: '/storage/scan/position-transfer',
		component: () => import('@/views/storage/scan/position-transfer'),
		name: 'storage-scan-position-transfer',
		meta: {
			title: 'storage-scan-position-transfer',
			icon: '',
			menu_Id: 381,
			menuName: '货位转移扫描',
      isOpenWindow: false
		}
	},{
		path: '/storage/transfer/position-transfer',
		component: () => import('@/views/storage/transfer/position-transfer'),
		name: 'storage-transfer-position-transfer',
		meta: {
			title: 'storage-transfer-position-transfer',
			icon: '',
			menu_Id: 450,
			menuName: '货位转移查询',
      isOpenWindow: false
		}
	},{
		path: '/storage/check/create-check',
		component: () => import('@/views/storage/check/create-check'),
		name: 'storage-check-create-check',
		meta: {
			title: 'storage-check-create-check',
			icon: '',
			menu_Id: 1528,
			menuName: '生成盘点单',
      isOpenWindow: false
		}
	},{
		path: '/storage/check/check',
		component: () => import('@/views/storage/check/check'),
		name: 'storage-check-check',
		meta: {
			title: 'storage-check-check',
			icon: '',
			menu_Id: 147,
			menuName: '库存盘点查询',
      isOpenWindow: false
		}
	},{
		path: '/storage/check/profit-loss',
		component: () => import('@/views/storage/check/profit-loss'),
		name: 'storage-check-profit-loss',
		meta: {
			title: 'storage-check-profit-loss',
			icon: '',
			menu_Id: 1565,
			menuName: '盘点盈亏单',
      isOpenWindow: false
		}
	},{
		path: '/storage/operation/enter',
		component: () => import('@/views/storage/operation/enter'),
		name: 'storage-operation-enter',
		meta: {
			title: 'storage-operation-enter',
			icon: '',
			menu_Id: 141,
			menuName: '其他入库单',
      isOpenWindow: false
		}
	},{
		path: '/storage/operation/outer',
		component: () => import('@/views/storage/operation/outer'),
		name: 'storage-operation-outer',
		meta: {
			title: 'storage-operation-outer',
			icon: '',
			menu_Id: 142,
			menuName: '其他出库单',
      isOpenWindow: false
		}
	},{
		path: '/storage/operation/purchase-price-adjust',
		component: () => import('@/views/storage/operation/purchase-price-adjust'),
		name: 'storage-operation-purchase-price-adjust',
		meta: {
			title: 'storage-operation-purchase-price-adjust',
			icon: '',
			menu_Id: 143,
			menuName: '库存成本调整单',
      isOpenWindow: false
		}
	},{
		path: '/storage/check/assemble',
		component: () => import('@/views/storage/check/assemble'),
		name: '/storage/check/assemble',
		meta: {
			title: '/storage/check/assemble',
			icon: '',
			menu_Id: 144,
			menuName: '商品拆装单',
      isOpenWindow: false
		}
	},{
		path: '/storage/operation/storage-adjust',
		component: () => import('@/views/storage/operation/storage-adjust'),
		name: 'storage-operation-storage-adjust',
		meta: {
			title: 'storage-operation-storage-adjust',
			icon: '',
			menu_Id: 228,
			menuName: '库存调整',
      isOpenWindow: false
		}
	},{
		path: '/storage/transfer/consignor-transfer',
		component: () => import('@/views/storage/transfer/consignor-transfer'),
		name: 'storage-transfer-consignor-transfer',
		meta: {
			title: 'storage-transfer-consignor-transfer',
			icon: '',
			menu_Id: 1686,
			menuName: '货主过户',
      isOpenWindow: false
		}
	},{
		path: '/storage/operation/valid-adjust',
		component: () => import('@/views/storage/operation/valid-adjust'),
		name: 'storage-operation-valid-adjust',
		meta: {
			title: 'storage-operation-valid-adjust',
			icon: '',
			menu_Id: 1688,
			menuName: '效期信息调整',
      isOpenWindow: false
		}
	}]
},{
	path: 'replenishment',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'replenishment',
	alwaysShow: true,
	meta: {
		title: 'replenishment',
		icon: '',
		menu_Id: 202,
    menuName: '补货管理',
    isOpenWindow: false
	},
	children:[{
		path: '/storage/replenishment/vbaseproductInfo-storagelower-replenishment',
		component: () => import('@/views/storage/replenishment/vbaseproductInfo-storagelower-replenishment'),
		name: 'vbaseproductInfo-storagelower-replenishment',
		meta: {
			title: 'vbaseproductInfo-storagelower-replenishment',
			icon: '',
			menu_Id: 203,
			menuName: '库存下限转补货单',
      isOpenWindow: false
		}
	},{
		path: '/storage/replenishment/vsale-orderlist-replenishment',
		component: () => import('@/views/storage/replenishment/vsale-orderlist-replenishment'),
		name: 'vsale-orderlist-replenishment',
		meta: {
			title: 'vsale-orderlist-replenishment',
			icon: '',
			menu_Id: 204,
			menuName: '缺货订单转补货单',
      isOpenWindow: false
		}
	},{
		path: '/storage/replenishment/storage-replenishment',
		component: () => import('@/views/storage/replenishment/storage-replenishment'),
		name: 'storage-replenishment',
		meta: {
			title: 'storage-replenishment',
			icon: '',
			menu_Id: 205,
			menuName: '补货单查询',
      isOpenWindow: false
		}
	},{
		path: '/storage/scan/replenishment-scan',
		component: () => import('@/views/storage/scan/replenishment-scan'),
		name: 'replenishment-scan',
		meta: {
			title: 'replenishment-scan',
			icon: '',
			menu_Id: 206,
			menuName: '补货单扫描',
      isOpenWindow: false
		}
	},{
		path: '/storage/replenishment/storage-replenishment-scan',
		component: () => import('@/views/storage/replenishment/storage-replenishment-scan'),
		name: 'storage-replenishment-scan',
		meta: {
			title: 'storage-replenishment-scan',
			icon: '',
			menu_Id: 207,
			menuName: '补货单扫描记录',
      isOpenWindow: false
		}
	},{
		path: '/storage/replenishment/storage-replenishment-sortingrule',
		component: () => import('@/views/storage/replenishment/storage-replenishment-sortingrule'),
		name: 'storage-replenishment-sortingrule',
		meta: {
			title: 'storage-replenishment-sortingrule',
			icon: '',
			menu_Id: 227,
			menuName: '补货分拣规则',
      isOpenWindow: false
		}
	}]
},{
	path: 'allocate',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'allocate',
	alwaysShow: true,
	meta: {
		title: 'allocate',
		icon: '',
		menu_Id: 138,
    menuName: '调拨管理',
    isOpenWindow: false
	},
	children:[{
		path: 'apply',
		component: () => import('@/views/storage/allocate/apply'),
		name: 'storage-allocate-apply',
		meta: {
			title: 'storage-allocate-apply',
			icon: '',
			menu_Id: 136,
			menuName: '调拨申请单',
      isOpenWindow: false
		}
	},{
		path: 'enter',
		component: () => import('@/views/storage/allocate/enter'),
		name: 'storage-allocate-enter',
		meta: {
			title: 'storage-allocate-enter',
			icon: '',
			menu_Id: 137,
			menuName: '调拨入库单',
      isOpenWindow: false
		}
	},{
		path: 'apply-detail',
		component: () => import('@/views/storage/allocate/apply-detail'),
		name: 'storage-allocate-apply-detail',
		meta: {
			title: 'storage-allocate-apply-detail',
			icon: '',
			menu_Id: 153,
			menuName: '商品调拨统计',
      isOpenWindow: false
		}
	}]
},{
	path: 'borrow',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'borrow',
	alwaysShow: true,
	meta: {
		title: 'borrow',
		icon: '',
		menu_Id: 146,
    menuName: '借欠管理',
    isOpenWindow: false
	},
	children:[{
		path: 'outer',
		component: () => import('@/views/storage/borrow/outer'),
		name: 'storage-borrow-outer',
		meta: {
			title: 'storage-borrow-outer',
			icon: '',
			menu_Id: 106,
			menuName: '借出单',
      isOpenWindow: false
		}
	},{
		path: 'outerreturn',
		component: () => import('@/views/storage/borrow/outerreturn'),
		name: 'storage-borrow-outerreturn',
		meta: {
			title: 'storage-borrow-outerreturn',
			icon: '',
			menu_Id: 118,
			menuName: '借出归还单',
      isOpenWindow: false
		}
	},{
		path: 'enter',
		component: () => import('@/views/storage/borrow/enter'),
		name: 'storage-borrow-enter',
		meta: {
			title: 'storage-borrow-enter',
			icon: '',
			menu_Id: 163,
			menuName: '借入单',
      isOpenWindow: false
		}
	},{
		path: 'enterreturn',
		component: () => import('@/views/storage/borrow/enterreturn'),
		name: 'storage-borrow-enterreturn',
		meta: {
			title: 'storage-borrow-enterreturn',
			icon: '',
			menu_Id: 166,
			menuName: '借入归还单',
      isOpenWindow: false
		}
	}]
},{
	path: 'setting',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'setting-allocate',
	alwaysShow: true,
	meta: {
		title: 'setting-allocate',
		icon: '',
		menu_Id: 244,
    menuName: '基础设置',
    isOpenWindow: false
	},
	children:[{
		path: 'storage',
		component: () => import('@/views/storage/setting/storage'),
		name: 'storage-setting-storage',
		meta: {
			title: 'storage-setting-storage',
			icon: '',
			menu_Id: 258,
			menuName: '仓库信息',
      isOpenWindow: false
		}
	},{
		path: 'storage-area',
		component: () => import('@/views/storage/setting/storage-area'),
		name: 'storage-setting-storage-area',
		meta: {
			title: 'storage-setting-storage-area',
			icon: '',
			menu_Id: 1561,
			menuName: '库区管理',
      isOpenWindow: false
		}
	},{
		path: 'position',
		component: () => import('@/views/storage/setting/position'),
		name: 'storage-setting-position',
		meta: {
			title: 'storage-setting-position',
			icon: '',
			menu_Id: 259,
			menuName: '货位管理',
      isOpenWindow: false
		}
	},{
		path: 'position-config',
		component: () => import('@/views/storage/setting/position-config'),
		name: 'storage-setting-position-config',
		meta: {
			title: 'storage-setting-position-config',
			icon: '',
			menu_Id: 1560,
			menuName: '货位定义',
      isOpenWindow: false
		}
	},{
		path: '/storage/setting/plate',
		component: () => import('@/views/storage/setting/plate'),
		name: 'storage-setting-plate',
		meta: {
			title: 'storage-setting-plate',
			icon: '',
			menu_Id: 1559,
			menuName: '容器管理',
      isOpenWindow: false
		}
	},{
		path: 'shelve-regular',
		component: () => import('@/views/storage/setting/shelve-regular'),
		name: 'storage-setting-shelve-regular',
		meta: {
			title: 'storage-setting-shelve-regular',
			icon: '',
			menu_Id: 1572,
			menuName: '商品入库上架策略',
      isOpenWindow: false
		}
	}]
}]
},{
	path: '/erp',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'erp',
	alwaysShow: true,
	meta: {
		title: 'erp',
		icon: 'menu-icon el-icon-yrt-cangchuguanli',
		menu_Id: 160,
    menuName: 'ERP',
    isOpenWindow: false
	},
	children:[{
	path: '/erp/sale',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'erp-sale-base',
	alwaysShow: true,
	meta: {
		title: 'erp-sale-base',
		icon: '',
		menu_Id: 406,
    menuName: '销售管理',
    isOpenWindow: false
	},
	children:[{
		path: '/erp/sale/plan-tob',
		component: () => import('@/views/erp/sale/plan-tob'),
		name: 'erp-sale-plan-tob',
		meta: {
			title: 'erp-sale-plan-tob',
			icon: '',
			menu_Id: 407,
			menuName: 'TOB销售计划',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/plan-b',
		component: () => import('@/views/erp/sale/plan-b'),
		name: 'erp-sale-plan-b',
		meta: {
			title: 'erp-sale-plan-b',
			icon: '',
			menu_Id: 427,
			menuName: '小B销售计划',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/plan-toc',
		component: () => import('@/views/erp/sale/plan-toc'),
		name: 'erp-sale-plan-toc',
		meta: {
			title: 'erp-sale-plan-toc',
			icon: '',
			menu_Id: 408,
			menuName: 'TOC销售计划',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/saleOrder-tob',
		component: () => import('@/views/erp/sale/saleOrder-tob'),
		name: 'erp-sale-saleOrder-tob',
		meta: {
			title: 'erp-sale-saleOrder-tob',
			icon: '',
			menu_Id: 426,
			menuName: 'TOB销售单',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/saleOrder-b',
		component: () => import('@/views/erp/sale/saleOrder-b'),
		name: 'erp-sale-saleOrder-b',
		meta: {
			title: 'erp-sale-saleOrder-b',
			icon: '',
			menu_Id: 429,
			menuName: '小B销售单',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/saleOrder-toc',
		component: () => import('@/views/erp/sale/saleOrder-toc'),
		name: 'erp-sale-saleOrder-toc',
		meta: {
			title: 'erp-sale-saleOrder-toc',
			icon: '',
			menu_Id: 452,
			menuName: 'TOC销售单',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/by-order-tob',
		component: () => import('@views/erp/sale/by-order-tob'),
		name: 'erp-sale-by-order-tob',
		meta: {
			title: 'erp-sale-by-order-tob',
			icon: '',
			menu_Id: 459,
			menuName: '下发TOB出库单',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/saleOrder-manage',
		component: () => import('@/views/erp/sale/saleOrder-manage'),
		name: 'erp-sale-saleOrder-manage',
		meta: {
			title: 'erp-sale-saleOrder-manage',
			icon: '',
			menu_Id: 428,
			menuName: '经管审核',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/saleOrder-affairs',
		component: () => import('@/views/erp/sale/saleOrder-affairs'),
		name: 'erp-sale-saleOrder-affairs',
		meta: {
			title: 'erp-sale-saleOrder-affairs',
			icon: '',
			menu_Id: 451,
			menuName: 'TOB商务审核',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/saleOrder-finance',
		component: () => import('@/views/erp/sale/saleOrder-finance'),
		name: 'erp-sale-saleOrder-finance',
		meta: {
			title: 'erp-sale-saleOrder-finance',
			icon: '',
			menu_Id: 457,
			menuName: 'TOB财务审核',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/saleOrder-produce',
		component: () => import('@/views/erp/sale/saleOrder-produce'),
		name: 'erp-sale-saleOrder-produce',
		meta: {
			title: 'erp-sale-saleOrder-produce',
			icon: '',
			menu_Id: 462,
			menuName: '生成TOB出口单',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/order-detail',
		component: () => import('@views/erp/sale/order-detail'),
		name: 'erp-sale-order-detail',
		meta: {
			title: 'erp-sale-order-detail',
			icon: '',
			menu_Id: 352,
			menuName: '按明细生成出口单',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/trackOrder',
		component: () => import('@/views/erp/sale/trackOrder'),
		name: 'erp-sale-trackOrder',
		meta: {
			title: 'erp-sale-trackOrder',
			icon: '',
			menu_Id: 463,
			menuName: '订单轨迹查询',
      isOpenWindow: false
		}
	}]
},{
	path: '/order',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'null',
	alwaysShow: true,
	meta: {
		title: 'null',
		icon: '',
		menu_Id: 1510,
    menuName: '订单处理',
    isOpenWindow: false
	},
	children:[{
		path: '/erp/sale/saleOrder-message',
		component: () => import('@/views/erp/sale/saleOrder-message'),
		name: 'erp-sale-saleOrder-message',
		meta: {
			title: 'erp-sale-saleOrder-message',
			icon: '',
			menu_Id: 1513,
			menuName: '留言待处理',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/saleOrder-address',
		component: () => import('@/views/erp/sale/saleOrder-address'),
		name: 'erp-sale-saleOrder-address',
		meta: {
			title: 'erp-sale-saleOrder-address',
			icon: '',
			menu_Id: 1514,
			menuName: '地址错误',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/saleOrder-noproduct',
		component: () => import('@/views/erp/sale/saleOrder-noproduct'),
		name: 'erp-sale-saleOrder-noproduct',
		meta: {
			title: 'erp-sale-saleOrder-noproduct',
			icon: '',
			menu_Id: 1516,
			menuName: '商品未知',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/saleOrder-noallot',
		component: () => import('@/views/erp/sale/saleOrder-noallot'),
		name: 'erp-sale-saleOrder-noallot',
		meta: {
			title: 'erp-sale-saleOrder-noallot',
			icon: '',
			menu_Id: 1518,
			menuName: '无法分配仓库',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/saleOrder-mailerror',
		component: () => import('@/views/erp/sale/saleOrder-mailerror'),
		name: 'erp-sale-saleOrder-mailerror',
		meta: {
			title: 'erp-sale-saleOrder-mailerror',
			icon: '',
			menu_Id: 1522,
			menuName: '邮寄方式错误',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/saleOrder-artificial',
		component: () => import('@/views/erp/sale/saleOrder-artificial'),
		name: 'erp-sale-saleOrder-artificial',
		meta: {
			title: 'erp-sale-saleOrder-artificial',
			icon: '',
			menu_Id: 1848,
			menuName: '需人工审核',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/saleOrder-backorders',
		component: () => import('@/views/erp/sale/saleOrder-backorders'),
		name: 'erp-sale-saleOrder-backorders',
		meta: {
			title: 'erp-sale-saleOrder-backorders',
			icon: '',
			menu_Id: 1855,
			menuName: '缺货订单',
      isOpenWindow: false
		}
	}]
},{
	path: '/logistics',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'logistics-base',
	alwaysShow: true,
	meta: {
		title: 'logistics-base',
		icon: '',
		menu_Id: 434,
    menuName: '物流估价审核',
    isOpenWindow: false
	},
	children:[{
		path: '/erp/sale/saleOrder-valuation',
		component: () => import('@/views/erp/sale/saleOrder-valuation'),
		name: 'erp-sale-saleOrder-valuation',
		meta: {
			title: 'erp-sale-saleOrder-valuation',
			icon: '',
			menu_Id: 225,
			menuName: '销售物流估价',
      isOpenWindow: false
		}
	},{
		path: '/erp/allocate/valuation',
		component: () => import('@/views/erp/allocate/valuation'),
		name: 'erp-allocate-valuation',
		meta: {
			title: 'erp-allocate-valuation',
			icon: '',
			menu_Id: 436,
			menuName: '调拨物流估价',
      isOpenWindow: false
		}
	}]
},{
	path: '/export',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'export-base',
	alwaysShow: true,
	meta: {
		title: 'export-base',
		icon: '',
		menu_Id: 286,
    menuName: '关务管理',
    isOpenWindow: false
	},
	children:[{
		path: '/erp/export/demand-tob',
		component: () => import('@views/erp/export/demand-tob'),
		name: 'erp-export-demand-tob',
		meta: {
			title: 'erp-export-demand-tob',
			icon: '',
			menu_Id: 287,
			menuName: 'TOB出口需求单',
      isOpenWindow: false
		}
	},{
		path: '/erp/export/demand',
		component: () => import('@views/erp/export/demand'),
		name: 'erp-export-demand',
		meta: {
			title: 'erp-export-demand',
			icon: '',
			menu_Id: 435,
			menuName: '调拨出口需求单',
      isOpenWindow: false
		}
	}]
},{
	path: '/erp/purchase',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'erp-purchase-base',
	alwaysShow: true,
	meta: {
		title: 'erp-purchase-base',
		icon: '',
		menu_Id: 281,
    menuName: '采购管理',
    isOpenWindow: false
	},
	children:[{
		path: '/erp/purchase/purchase-plan',
		component: () => import('@views/erp/purchase/purchase-plan'),
		name: 'erp-purchase-purchase-plan',
		meta: {
			title: 'erp-purchase-purchase-plan',
			icon: '',
			menu_Id: 354,
			menuName: '采购计划需求',
      isOpenWindow: false
		}
	},{
		path: '/erp/purchase/un-plan',
		component: () => import('@views/erp/purchase/un-plan'),
		name: 'erp-purchase-un-plan',
		meta: {
			title: 'erp-purchase-un-plan',
			icon: '',
			menu_Id: 333,
			menuName: '计划外需求',
      isOpenWindow: false
		}
	},{
		path: '/erp/purchase/plan-submit',
		component: () => import('@views/erp/purchase/plan-submit'),
		name: 'erp-purchase-plan-submit',
		meta: {
			title: 'erp-purchase-plan-submit',
			icon: '',
			menu_Id: 437,
			menuName: '采购提交',
      isOpenWindow: false
		}
	},{
		path: '/erp/purchase/plan-detail-submit',
		component: () => import('@/views/erp/purchase/plan-detail-submit'),
		name: 'erp-purchase-plan-detail-submit',
		meta: {
			title: 'erp-purchase-plan-detail-submit',
			icon: '',
			menu_Id: 1864,
			menuName: '采购明细提交',
      isOpenWindow: false
		}
	},{
		path: '/erp/purchase/order',
		component: () => import('@views/erp/purchase/order'),
		name: 'erp-purchase-order',
		meta: {
			title: 'erp-purchase-order',
			icon: '',
			menu_Id: 438,
			menuName: '采购订单',
      isOpenWindow: false
		}
	},{
		path: '/erp/purchase/return',
		component: () => import('@/views/erp/purchase/return'),
		name: 'erp-purchase-return',
		meta: {
			title: 'erp-purchase-return',
			icon: '',
			menu_Id: 439,
			menuName: '采购退货',
      isOpenWindow: false
		}
	},{
		path: '/erp/purchase/exchange',
		component: () => import('@/views/erp/purchase/exchange'),
		name: 'erp-purchase-exchange',
		meta: {
			title: 'erp-purchase-exchange',
			icon: '',
			menu_Id: 443,
			menuName: '采购换货',
      isOpenWindow: false
		}
	}]
},{
	path: '/erp/promotion',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'erp-promotion-base',
	alwaysShow: true,
	meta: {
		title: 'erp-promotion-base',
		icon: '',
		menu_Id: 1870,
    menuName: '营销管理',
    isOpenWindow: false
	},
	children:[{
		path: '/erp/promotion/promotionmanage/promotion-product',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-product'),
		name: 'erp-promotion-promotionmanage-promotion-product',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-product',
			icon: '',
			menu_Id: 1872,
			menuName: '商品促销',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-order',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-order'),
		name: 'erp-promotion-promotionmanage-promotion-order',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-order',
			icon: '',
			menu_Id: 1873,
			menuName: '订单促销',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-merge',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-merge'),
		name: 'erp-promotion-promotionmanage-promotion-merge',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-merge',
			icon: '',
			menu_Id: 1874,
			menuName: '组合促销',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-freecharge',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-freecharge'),
		name: 'erp-promotion-promotionmanage-promotion-freecharge',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-freecharge',
			icon: '',
			menu_Id: 1875,
			menuName: '免费领活动',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-makegroup',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-makegroup'),
		name: 'erp-promotion-promotionmanage-promotion-makegroup',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-makegroup',
			icon: '',
			menu_Id: 1876,
			menuName: '拼团活动',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-bargaining',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-bargaining'),
		name: 'erp-promotion-promotionmanage-promotion-bargaining',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-bargaining',
			icon: '',
			menu_Id: 1877,
			menuName: '砍价活动',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-flashSale',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-flashSale'),
		name: 'erp-promotion-promotionmanage-promotion-flashSale',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-flashSale',
			icon: '',
			menu_Id: 1878,
			menuName: '限时特卖活动',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-cilentvip',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-cilentvip'),
		name: 'erp-promotion-promotionmanage-promotion-cilentvip',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-cilentvip',
			icon: '',
			menu_Id: 1881,
			menuName: '会员',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-integral',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-integral'),
		name: 'erp-promotion-promotionmanage-promotion-integral',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-integral',
			icon: '',
			menu_Id: 1882,
			menuName: '积分',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-turntable',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-turntable'),
		name: 'erp-promotion-promotionmanage-promotion-turntable',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-turntable',
			icon: '',
			menu_Id: 1883,
			menuName: '大转盘',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-coupon',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-coupon'),
		name: 'erp-promotion-promotionmanage-promotion-coupon',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-coupon',
			icon: '',
			menu_Id: 1884,
			menuName: '优惠券',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-advertise',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-advertise'),
		name: 'erp-promotion-promotionmanage-promotion-advertise',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-advertise',
			icon: '',
			menu_Id: 1885,
			menuName: '广告发布',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-notice',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-notice'),
		name: 'erp-promotion-promotionmanage-promotion-notice',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-notice',
			icon: '',
			menu_Id: 1886,
			menuName: '通知公告',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-recharge',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-recharge'),
		name: 'erp-promotion-promotionmanage-promotion-recharge',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-recharge',
			icon: '',
			menu_Id: 1887,
			menuName: '短信充值',
      isOpenWindow: false
		}
	},{
		path: '/erp/promotion/promotionmanage/promotion-noticeSetup',
		component: () => import('@/views/erp/promotion/promotionmanage/promotion-noticeSetup'),
		name: 'erp-promotion-promotionmanage-promotion-noticeSetup',
		meta: {
			title: 'erp-promotion-promotionmanage-promotion-noticeSetup',
			icon: '',
			menu_Id: 1888,
			menuName: '通知设置',
      isOpenWindow: false
		}
	}]
},{
	path: '/allocate',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'allocate-base',
	alwaysShow: true,
	meta: {
		title: 'allocate-base',
		icon: '',
		menu_Id: 444,
    menuName: '调拨管理',
    isOpenWindow: false
	},
	children:[{
		path: '/erp/allocate/apply',
		component: () => import('@/views/erp/allocate/apply'),
		name: 'erp-allocate-apply',
		meta: {
			title: 'erp-allocate-apply',
			icon: '',
			menu_Id: 485,
			menuName: '调拨申请单',
      isOpenWindow: false
		}
	},{
		path: '/erp/allocate/apply-export',
		component: () => import('@/views/erp/allocate/apply-export'),
		name: 'erp-allocate-apply-export',
		meta: {
			title: 'erp-allocate-apply-export',
			icon: '',
			menu_Id: 1674,
			menuName: '生成调拨出口单',
      isOpenWindow: false
		}
	},{
		path: '/erp/allocate/apply-export-detail',
		component: () => import('@views/erp/allocate/apply-export-detail'),
		name: 'erp-allocate-apply-export-detail',
		meta: {
			title: 'erp-allocate-apply-export-detail',
			icon: '',
			menu_Id: 360,
			menuName: '按明细生成出口单',
      isOpenWindow: false
		}
	},{
		path: '/erp/allocate/enter',
		component: () => import('@/views/erp/allocate/enter'),
		name: 'erp-allocate-enter',
		meta: {
			title: 'erp-allocate-enter',
			icon: '',
			menu_Id: 133,
			menuName: '调拨入库单',
      isOpenWindow: false
		}
	}]
},{
	path: '/finance',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'finance-base',
	alwaysShow: true,
	meta: {
		title: 'finance-base',
		icon: '',
		menu_Id: 131,
    menuName: '财务管理',
    isOpenWindow: false
	},
	children:[{
		path: '/erp/sale/receivable-tob',
		component: () => import('@/views/erp/sale/receivable-tob'),
		name: 'erp-sale-receivable-tob',
		meta: {
			title: 'erp-sale-receivable-tob',
			icon: '',
			menu_Id: 132,
			menuName: 'TOB销售应收',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/receivable-b',
		component: () => import('@/views/erp/sale/receivable-b'),
		name: 'erp-sale-receivable-b',
		meta: {
			title: 'erp-sale-receivable-b',
			icon: '',
			menu_Id: 1670,
			menuName: '小B销售应收',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/receivable-toc',
		component: () => import('@/views/erp/sale/receivable-toc'),
		name: 'erp-sale-receivable-toc',
		meta: {
			title: 'erp-sale-receivable-toc',
			icon: '',
			menu_Id: 1672,
			menuName: 'TOC销售应收',
      isOpenWindow: false
		}
	},{
		path: '/erp/purchase/order-pay',
		component: () => import('@/views/erp/purchase/order-pay'),
		name: 'erp-purchase-order-pay',
		meta: {
			title: 'erp-purchase-order-pay',
			icon: '',
			menu_Id: 283,
			menuName: '采购应付',
      isOpenWindow: false
		}
	},{
		path: '/erp/pay/apply',
		component: () => import('@/views/erp/pay/apply'),
		name: 'erp-pay-apply',
		meta: {
			title: 'erp-pay-apply',
			icon: '',
			menu_Id: 157,
			menuName: '付款申请单',
      isOpenWindow: false
		}
	},{
		path: '/erp/allocate/apply-put',
		component: () => import('@/views/erp/allocate/apply-put'),
		name: 'erp-allocate-apply-put',
		meta: {
			title: 'erp-allocate-apply-put',
			icon: '',
			menu_Id: 158,
			menuName: '调拨应收',
      isOpenWindow: false
		}
	},{
		path: '/erp/allocate/apply-pay',
		component: () => import('@/views/erp/allocate/apply-pay'),
		name: 'erp-allocate-apply-pay',
		meta: {
			title: 'erp-allocate-apply-pay',
			icon: '',
			menu_Id: 159,
			menuName: '调拨应付',
      isOpenWindow: false
		}
	}]
},{
	path: '/erp/service-base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'erp-service-base',
	alwaysShow: true,
	meta: {
		title: 'erp-service-base',
		icon: '',
		menu_Id: 331,
    menuName: '客服中心',
    isOpenWindow: false
	},
	children:[{
		path: '/erp/sale/return',
		component: () => import('@/views/erp/sale/return'),
		name: 'erp-sale-return',
		meta: {
			title: 'erp-sale-return',
			icon: '',
			menu_Id: 1715,
			menuName: '销售退货单',
      isOpenWindow: false
		}
	},{
		path: '/erp/sale/exchange',
		component: () => import('@/views/erp/sale/exchange'),
		name: 'erp-sale-exchange',
		meta: {
			title: 'erp-sale-exchange',
			icon: '',
			menu_Id: 292,
			menuName: '销售换货单',
      isOpenWindow: false
		}
	}]
},{
	path: '/channel',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'channel-base',
	alwaysShow: true,
	meta: {
		title: 'channel-base',
		icon: '',
		menu_Id: 369,
    menuName: '渠道分货管理',
    isOpenWindow: false
	},
	children:[{
		path: '/erp/channel/cargo',
		component: () => import('@/views/erp/channel/cargo'),
		name: 'erp-channel-cargo',
		meta: {
			title: 'erp-channel-cargo',
			icon: '',
			menu_Id: 370,
			menuName: '渠道分货管理',
      isOpenWindow: false
		}
	},{
		path: '/erp/channel/product-place-holder',
		component: () => import('@/views/erp/channel/product-place-holder'),
		name: 'erp-channel-product-place-holder',
		meta: {
			title: 'erp-channel-product-place-holder',
			icon: '',
			menu_Id: 393,
			menuName: '渠道分货查询',
      isOpenWindow: false
		}
	}]
},{
	path: '/erp/base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'erp-base',
	alwaysShow: true,
	meta: {
		title: 'erp-base',
		icon: '',
		menu_Id: 1863,
    menuName: '基础设置',
    isOpenWindow: false
	},
	children:[{
		path: '/erp/base/channel',
		component: () => import('@/views/erp/base/channel'),
		name: 'erp-base-channel',
		meta: {
			title: 'erp-base-channel',
			icon: '',
			menu_Id: 400,
			menuName: '渠道管理',
      isOpenWindow: false
		}
	},{
		path: '/erp/base/rate',
		component: () => import('@views/erp/base/rate'),
		name: 'erp-base-rate',
		meta: {
			title: 'erp-base-rate',
			icon: '',
			menu_Id: 460,
			menuName: '汇率设置',
      isOpenWindow: false
		}
	},{
		path: '/erp/base/order-auto-rule',
		component: () => import('@/views/erp/base/order-auto-rule'),
		name: 'erp-base-order-auto-rule',
		meta: {
			title: 'erp-base-order-auto-rule',
			icon: '',
			menu_Id: 1861,
			menuName: '订单自动规则',
      isOpenWindow: false
		}
	}]
}]
},{
	path: '/cptms',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'cptms',
	alwaysShow: true,
	meta: {
		title: 'cptms',
		icon: 'menu-icon el-icon-yrt-wuliuguanliicon',
		menu_Id: 126,
    menuName: 'TMS',
    isOpenWindow: false
	},
	children:[{
	path: 'cptms-basic',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'cptms-basic',
	alwaysShow: true,
	meta: {
		title: 'cptms-basic',
		icon: '',
		menu_Id: 1766,
    menuName: '基础信息',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/site/tms-line',
		component: () => import('@/views/tms/site/tms-line'),
		name: 'tms-site-tms-line',
		meta: {
			title: 'tms-site-tms-line',
			icon: '',
			menu_Id: 1799,
			menuName: '线路管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/site/tms-client-line',
		component: () => import('@/views/tms/site/tms-client-line'),
		name: 'tms-site-tms-client-line',
		meta: {
			title: 'tms-site-tms-client-line',
			icon: '',
			menu_Id: 1813,
			menuName: '客户线路规则',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/consignor/base-consignorStore',
		component: () => import('@/views/basicInfo/consignor/base-consignorStore'),
		name: 'basicInfo-consignor-base-consignorStore',
		meta: {
			title: 'basicInfo-consignor-base-consignorStore',
			icon: '',
			menu_Id: 1776,
			menuName: '客户门店管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/consignor/base-consignorContract',
		component: () => import('@/views/basicInfo/consignor/base-consignorContract'),
		name: 'basicInfo-consignor-base-consignorContract',
		meta: {
			title: 'basicInfo-consignor-base-consignorContract',
			icon: '',
			menu_Id: 1626,
			menuName: '客户合同管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/site/tms-site',
		component: () => import('@/views/tms/site/tms-site'),
		name: 'tms-site-tms-site',
		meta: {
			title: 'tms-site-tms-site',
			icon: '',
			menu_Id: 1798,
			menuName: '网点管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/consignor/base-consignorStoreAddress',
		component: () => import('@/views/basicInfo/consignor/base-consignorStoreAddress'),
		name: 'basicInfo-consignor-base-consignorStoreAddress',
		meta: {
			title: 'basicInfo-consignor-base-consignorStoreAddress',
			icon: '',
			menu_Id: 1781,
			menuName: '收货人管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/consignor/base-consignorAddress',
		component: () => import('@/views/basicInfo/consignor/base-consignorAddress'),
		name: 'basicInfo-consignor-base-consignorAddress',
		meta: {
			title: 'basicInfo-consignor-base-consignorAddress',
			icon: '',
			menu_Id: 1789,
			menuName: '发货人管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/tms/base-carrier',
		component: () => import('@/views/basicInfo/tms/base-carrier'),
		name: 'basicInfo-tms-base-carrier',
		meta: {
			title: 'basicInfo-tms-base-carrier',
			icon: '',
			menu_Id: 1764,
			menuName: '承运商管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/service/tms-servicePart',
		component: () => import('@/views/tms/service/tms-servicePart'),
		name: 'tms-service-tms-servicePart',
		meta: {
			title: 'tms-service-tms-servicePart',
			icon: '',
			menu_Id: 1774,
			menuName: '维修配件管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/base/base-park',
		component: () => import('@/views/basicInfo/base/base-park'),
		name: 'basicInfo-base-base-park',
		meta: {
			title: 'basicInfo-base-base-park',
			icon: '',
			menu_Id: 1778,
			menuName: '物流园区',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/storage/base-platform',
		component: () => import('@/views/basicInfo/storage/base-platform'),
		name: 'basicInfo-storage-base-platform',
		meta: {
			title: 'basicInfo-storage-base-platform',
			icon: '',
			menu_Id: 1779,
			menuName: '月台管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/storage/base-storageLease',
		component: () => import('@/views/basicInfo/storage/base-storageLease'),
		name: 'basicInfo-storage-base-storageLease',
		meta: {
			title: 'basicInfo-storage-base-storageLease',
			icon: '',
			menu_Id: 1780,
			menuName: '租赁管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/provider/base-providerContract',
		component: () => import('@/views/basicInfo/provider/base-providerContract'),
		name: 'basicInfo-provider-base-providerContract',
		meta: {
			title: 'basicInfo-provider-base-providerContract',
			icon: '',
			menu_Id: 1783,
			menuName: '供应商合同管理',
      isOpenWindow: false
		}
	}]
},{
	path: 'cptms-car',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'cptms-car',
	alwaysShow: true,
	meta: {
		title: 'cptms-car',
		icon: '',
		menu_Id: 1768,
    menuName: '司机&车辆',
    isOpenWindow: false
	},
	children:[{
		path: '/basicInfo/tms/base-vehicleGroup',
		component: () => import('@/views/basicInfo/tms/base-vehicleGroup'),
		name: 'basicInfo-tms-base-vehicleGroup',
		meta: {
			title: 'basicInfo-tms-base-vehicleGroup',
			icon: '',
			menu_Id: 193,
			menuName: '车队管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/tms/base-driver',
		component: () => import('@/views/basicInfo/tms/base-driver'),
		name: 'basicInfo-tms-base-driver',
		meta: {
			title: 'basicInfo-tms-base-driver',
			icon: '',
			menu_Id: 1627,
			menuName: '司机管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/tms/base-driverContract',
		component: () => import('@/views/basicInfo/tms/base-driverContract'),
		name: 'basicInfo-tms-base-driverContract',
		meta: {
			title: 'basicInfo-tms-base-driverContract',
			icon: '',
			menu_Id: 1628,
			menuName: '司机合同管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/tms/base-vehicle',
		component: () => import('@/views/basicInfo/tms/base-vehicle'),
		name: 'basicInfo-tms-base-vehicle',
		meta: {
			title: 'basicInfo-tms-base-vehicle',
			icon: '',
			menu_Id: 1629,
			menuName: '车辆管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/tms/base-driverVehicle',
		component: () => import('@/views/basicInfo/tms/base-driverVehicle'),
		name: 'basiclnfo-tms-base-driverVehicle',
		meta: {
			title: 'basiclnfo-tms-base-driverVehicle',
			icon: '',
			menu_Id: 1630,
			menuName: '司机车辆绑定',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/tms/base-trailer',
		component: () => import('@/views/basicInfo/tms/base-trailer'),
		name: 'basicInfo-tms-base-trailer',
		meta: {
			title: 'basicInfo-tms-base-trailer',
			icon: '',
			menu_Id: 1765,
			menuName: '挂车管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/fee/tms-subsidy',
		component: () => import('@/views/tms/fee/tms-subsidy'),
		name: 'tms-fee-tms-subsidy',
		meta: {
			title: 'tms-fee-tms-subsidy',
			icon: '',
			menu_Id: 1772,
			menuName: '车辆补贴管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/service/tms-feedback',
		component: () => import('@/views/tms/service/tms-feedback'),
		name: 'tms-service-tms-feedback',
		meta: {
			title: 'tms-service-tms-feedback',
			icon: '',
			menu_Id: 1773,
			menuName: '司机反馈',
      isOpenWindow: false
		}
	}]
},{
	path: 'waybill-base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'waybill-base',
	alwaysShow: true,
	meta: {
		title: 'waybill-base',
		icon: '',
		menu_Id: 497,
    menuName: '运单中心',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/way/bill',
		component: () => import('@/views/tms/way/bill'),
		name: 'tms-way-bill',
		meta: {
			title: 'tms-way-bill',
			icon: '',
			menu_Id: 499,
			menuName: '运单管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/vtms-bill-list',
		component: () => import('@/views/tms/way/vtms-bill-list'),
		name: 'tms-way-vtms-bill-list',
		meta: {
			title: 'tms-way-vtms-bill-list',
			icon: '',
			menu_Id: 298,
			menuName: '运单明细查询',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/package-import',
		component: () => import('@/views/tms/way/package-import'),
		name: 'tms-way-package-import',
		meta: {
			title: 'tms-way-package-import',
			icon: '',
			menu_Id: 1756,
			menuName: '运单更新导入',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/routertask',
		component: () => import('@/views/tms/way/routertask'),
		name: 'tms-way-routertask',
		meta: {
			title: 'tms-way-routertask',
			icon: '',
			menu_Id: 446,
			menuName: '轨迹作业任务',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/bill-tracking',
		component: () => import('@/views/tms/way/bill-tracking'),
		name: 'tms-way-bill-tracking',
		meta: {
			title: 'tms-way-bill-tracking',
			icon: '',
			menu_Id: 498,
			menuName: '运单追踪',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/voyage',
		component: () => import('@/views/tms/way/voyage'),
		name: 'tms-way-voyage',
		meta: {
			title: 'tms-way-voyage',
			icon: '',
			menu_Id: 504,
			menuName: '航单主单号',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/platformInquiry',
		component: () => import('@/views/tms/way/platformInquiry'),
		name: 'tms-way-platformInquiry',
		meta: {
			title: 'tms-way-platformInquiry',
			icon: '',
			menu_Id: 1784,
			menuName: '月台实时查询',
      isOpenWindow: false
		}
	}]
},{
	path: 'business-tms',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'business-tms',
	alwaysShow: true,
	meta: {
		title: 'business-tms',
		icon: '',
		menu_Id: 1797,
    menuName: '提货管理',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/way/bill-pickup',
		component: () => import('@/views/tms/way/bill-pickup'),
		name: 'tms-way-bill-pickup',
		meta: {
			title: 'tms-way-bill-pickup',
			icon: '',
			menu_Id: 1733,
			menuName: '提货任务',
      isOpenWindow: false
		}
	},{
		path: '/tms/biz/tms-pickup',
		component: () => import('@/views/tms/biz/tms-pickup'),
		name: 'tms-biz-tms-pickup',
		meta: {
			title: 'tms-biz-tms-pickup',
			icon: '',
			menu_Id: 1800,
			menuName: '提货派车单',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/vtms-bill-list-pickup',
		component: () => import('@/views/tms/way/vtms-bill-list-pickup'),
		name: 'tms-way-vtms-bill-list-pickup',
		meta: {
			title: 'tms-way-vtms-bill-list-pickup',
			icon: '',
			menu_Id: 1579,
			menuName: '提货按明细派车',
      isOpenWindow: false
		}
	}]
},{
	path: 'transport-tms',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'transport-tms',
	alwaysShow: true,
	meta: {
		title: 'transport-tms',
		icon: '',
		menu_Id: 242,
    menuName: '干线运输管理',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/way/bill-shipment',
		component: () => import('@/views/tms/way/bill-shipment'),
		name: 'tms-way-bill-shipment',
		meta: {
			title: 'tms-way-bill-shipment',
			icon: '',
			menu_Id: 1734,
			menuName: '发货库存',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/vtms-bill-list-shipment',
		component: () => import('@/views/tms/way/vtms-bill-list-shipment'),
		name: 'tms-way-vtms-bill-list-shipment',
		meta: {
			title: 'tms-way-vtms-bill-list-shipment',
			icon: '',
			menu_Id: 1584,
			menuName: '发货库存明细载配',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/bill-fleet',
		component: () => import('@/views/tms/way/bill-fleet'),
		name: 'tms-way-bill-fleet',
		meta: {
			title: 'tms-way-bill-fleet',
			icon: '',
			menu_Id: 189,
			menuName: '车队运输任务',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/bill-arrival',
		component: () => import('@/views/tms/way/bill-arrival'),
		name: 'tms-way-bill-arrival',
		meta: {
			title: 'tms-way-bill-arrival',
			icon: '',
			menu_Id: 1732,
			menuName: '到货库存',
      isOpenWindow: false
		}
	},{
		path: '/tms/biz/tms-transport',
		component: () => import('@/views/tms/biz/tms-transport'),
		name: 'tms-biz-tms-transport',
		meta: {
			title: 'tms-biz-tms-transport',
			icon: '',
			menu_Id: 1801,
			menuName: '干线运输单',
      isOpenWindow: false
		}
	}]
},{
	path: 'distribution-biz',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'distribution-biz',
	alwaysShow: true,
	meta: {
		title: 'distribution-biz',
		icon: '',
		menu_Id: 1683,
    menuName: '城配管理',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/way/bill-distribution',
		component: () => import('@/views/tms/way/bill-distribution'),
		name: 'tms-way-bill-distribution',
		meta: {
			title: 'tms-way-bill-distribution',
			icon: '',
			menu_Id: 230,
			menuName: '配送任务',
      isOpenWindow: false
		}
	},{
		path: '/tms/biz/tms-distribution',
		component: () => import('@/views/tms/biz/tms-distribution'),
		name: 'tms-biz-tms-distribution',
		meta: {
			title: 'tms-biz-tms-distribution',
			icon: '',
			menu_Id: 1802,
			menuName: '配送派车单',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/vtms-bill-list-distribution',
		component: () => import('@/views/tms/way/vtms-bill-list-distribution'),
		name: 'tms-way-vtms-bill-list-distribution',
		meta: {
			title: 'tms-way-vtms-bill-list-distribution',
			icon: '',
			menu_Id: 374,
			menuName: '配送任务明细查询',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/scan/send-scan',
		component: () => import('@/views/tms/way/scan/send-scan'),
		name: 'tms-way-send-scan',
		meta: {
			title: 'tms-way-send-scan',
			icon: '',
			menu_Id: 1818,
			menuName: '配送装车扫描',
      isOpenWindow: false
		}
	}]
},{
	path: 'oil-tms',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'oil-tms',
	alwaysShow: true,
	meta: {
		title: 'oil-tms',
		icon: '',
		menu_Id: 280,
    menuName: '油卡管理',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/oil/tms-oilcard',
		component: () => import('@/views/tms/oil/tms-oilcard'),
		name: 'tms-oil-tms-oilcard',
		meta: {
			title: 'tms-oil-tms-oilcard',
			icon: '',
			menu_Id: 375,
			menuName: '油卡管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/oil/tms-oilrecharge',
		component: () => import('@/views/tms/oil/tms-oilrecharge'),
		name: 'tms-oil-tms-oilrecharge',
		meta: {
			title: 'tms-oil-tms-oilrecharge',
			icon: '',
			menu_Id: 376,
			menuName: '油卡充值',
      isOpenWindow: false
		}
	},{
		path: '/tms/oil/tms-oilconsume',
		component: () => import('@/views/tms/oil/tms-oilconsume'),
		name: 'tms-oil-tms-oilconsume',
		meta: {
			title: 'tms-oil-tms-oilconsume',
			icon: '',
			menu_Id: 377,
			menuName: '油卡消费',
      isOpenWindow: false
		}
	},{
		path: '/tms/oil/tms-oilsetup',
		component: () => import('@/views/tms/oil/tms-oilsetup'),
		name: 'tms-oil-tms-oilsetup',
		meta: {
			title: 'tms-oil-tms-oilsetup',
			icon: '',
			menu_Id: 1805,
			menuName: '油卡设置',
      isOpenWindow: false
		}
	},{
		path: '/tms/oil/tms-collectionrecord',
		component: () => import('@/views/tms/oil/tms-collectionrecord'),
		name: 'tms-oil-tms-collectionrecord',
		meta: {
			title: 'tms-oil-tms-collectionrecord',
			icon: '',
			menu_Id: 1806,
			menuName: '油卡领用记录',
      isOpenWindow: false
		}
	}]
},{
	path: 'location-biz',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'location-biz',
	alwaysShow: true,
	meta: {
		title: 'location-biz',
		icon: '',
		menu_Id: 1819,
    menuName: '轨迹管理',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/way/map/car-location-map',
		component: () => import('@/views/tms/way/map/car-location-map'),
		name: 'car-location-map',
		meta: {
			title: 'car-location-map',
			icon: '',
			menu_Id: 1820,
			menuName: '实时定位',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/map/car-trail-map',
		component: () => import('@/views/tms/way/map/car-trail-map'),
		name: 'car-trail-map',
		meta: {
			title: 'car-trail-map',
			icon: '',
			menu_Id: 1821,
			menuName: '轨迹回放',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/car-trail',
		component: () => import('@/views/tms/way/car-trail'),
		name: 'car-trail',
		meta: {
			title: 'car-trail',
			icon: '',
			menu_Id: 1826,
			menuName: '轨迹列表',
      isOpenWindow: false
		}
	}]
},{
	path: 'tms-service-base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'tms-service-base',
	alwaysShow: true,
	meta: {
		title: 'tms-service-base',
		icon: '',
		menu_Id: 502,
    menuName: '客服管理',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/oa/sms',
		component: () => import('@/views/tms/oa/sms'),
		name: 'tms-oa-sms',
		meta: {
			title: 'tms-oa-sms',
			icon: '',
			menu_Id: 464,
			menuName: '短信管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/bill-abnormal',
		component: () => import('@/views/tms/way/bill-abnormal'),
		name: 'tms-way-bill-abnormal',
		meta: {
			title: 'tms-way-bill-abnormal',
			icon: '',
			menu_Id: 1730,
			menuName: '异常处理',
      isOpenWindow: false
		}
	},{
		path: '/tms/service/claim',
		component: () => import('@/views/tms/service/claim'),
		name: 'tms-service-claim',
		meta: {
			title: 'tms-service-claim',
			icon: '',
			menu_Id: 1731,
			menuName: '理赔处理',
      isOpenWindow: false
		}
	}]
},{
	path: 'parcelpanel-base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'parcelpanel-base',
	alwaysShow: true,
	meta: {
		title: 'parcelpanel-base',
		icon: '',
		menu_Id: 239,
    menuName: '包裹组板',
    isOpenWindow: false
	},
	children:[{
		path: '/views/tms/panel/sorting-printer',
		component: () => import('@/views/tms/panel/sorting-printer'),
		name: 'tms-panel-sorting-printer',
		meta: {
			title: 'tms-panel-sorting-printer',
			icon: '',
			menu_Id: 299,
			menuName: '分拣机打印',
      isOpenWindow: false
		}
	},{
		path: '/views/tms/panel/sorting-printer-pda',
		component: () => import('@/views/tms/panel/sorting-printer-pda'),
		name: 'tms-panel-sorting-printer-pda',
		meta: {
			title: 'tms-panel-sorting-printer-pda',
			icon: '',
			menu_Id: 306,
			menuName: 'PDA打印机',
      isOpenWindow: false
		}
	},{
		path: '/views/tms/panel/pcpanel',
		component: () => import('@/views/tms/panel/pcpanel'),
		name: 'tms-panel-pcpanel',
		meta: {
			title: 'tms-panel-pcpanel',
			icon: '',
			menu_Id: 1747,
			menuName: 'PC组板',
      isOpenWindow: false
		}
	},{
		path: '/tms/panel/goodsregion',
		component: () => import('@/views/tms/panel/goodsregion'),
		name: 'tms-panel-goodsregion',
		meta: {
			title: 'tms-panel-goodsregion',
			icon: '',
			menu_Id: 1748,
			menuName: '落件格口设置',
      isOpenWindow: false
		}
	},{
		path: '/tms/panel/panel',
		component: () => import('@/views/tms/panel/panel'),
		name: 'tms-panel-panel',
		meta: {
			title: 'tms-panel-panel',
			icon: '',
			menu_Id: 1749,
			menuName: '组板查询',
      isOpenWindow: false
		}
	},{
		path: '/tms/panel/sortingpanellog',
		component: () => import('@/views/tms/panel/sortingpanellog'),
		name: 'tms-panel-sortingpanellog',
		meta: {
			title: 'tms-panel-sortingpanellog',
			icon: '',
			menu_Id: 1750,
			menuName: '流水线校验结果',
      isOpenWindow: false
		}
	}]
},{
	path: 'bill-base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'bill-base',
	alwaysShow: true,
	meta: {
		title: 'bill-base',
		icon: '',
		menu_Id: 489,
    menuName: '面单管理',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/way/billother',
		component: () => import('@/views/tms/way/billother'),
		name: 'tms-way-billother',
		meta: {
			title: 'tms-way-billother',
			icon: '',
			menu_Id: 1757,
			menuName: '报关面单',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/bill-receive',
		component: () => import('@/views/tms/way/bill-receive'),
		name: 'tms-way-bill-receive',
		meta: {
			title: 'tms-way-bill-receive',
			icon: '',
			menu_Id: 492,
			menuName: '揽收查询',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/upload',
		component: () => import('@/views/tms/way/upload'),
		name: 'tms-way-upload',
		meta: {
			title: 'tms-way-upload',
			icon: '',
			menu_Id: 493,
			menuName: '面单上传',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/upload-search',
		component: () => import('@/views/tms/way/upload-search'),
		name: 'tms-way-upload-search',
		meta: {
			title: 'tms-way-upload-search',
			icon: 'tms-way-query-service',
			menu_Id: 191,
			menuName: '面单上传查询',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/bill-editor',
		component: () => import('@/views/tms/way/bill-editor'),
		name: 'tms-way-bill-editor',
		meta: {
			title: 'tms-way-bill-editor',
			icon: '',
			menu_Id: 494,
			menuName: '面单录入',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/query-service',
		component: () => import('@/views/tms/way/query-service'),
		name: 'tms-way-query-service',
		meta: {
			title: 'tms-way-query-service',
			icon: '',
			menu_Id: 1758,
			menuName: '客服录入统计',
      isOpenWindow: false
		}
	}]
},{
	path: 'tms-basic',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'tms-basic',
	alwaysShow: true,
	meta: {
		title: 'tms-basic',
		icon: '',
		menu_Id: 1737,
    menuName: '跨境基础设置',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/basic/idcard',
		component: () => import('@/views/tms/basic/idcard'),
		name: 'tms-basic-idcard',
		meta: {
			title: 'tms-basic-idcard',
			icon: '',
			menu_Id: 1736,
			menuName: '身份证库',
      isOpenWindow: false
		}
	},{
		path: '/tms/basic/task',
		component: () => import('@/views/tms/basic/task'),
		name: 'tms-basic-task',
		meta: {
			title: 'tms-basic-task',
			icon: '',
			menu_Id: 503,
			menuName: '作业任务',
      isOpenWindow: false
		}
	},{
		path: '/tms/basic/fee-item',
		component: () => import('@/views/tms/basic/fee-item'),
		name: 'tms-basic-fee-item',
		meta: {
			title: 'tms-basic-fee-item',
			icon: '',
			menu_Id: 1743,
			menuName: '收费项目基础',
      isOpenWindow: false
		}
	},{
		path: '/tms/basic/fee-template',
		component: () => import('@/views/tms/basic/fee-template'),
		name: 'tms-basic-fee-template',
		meta: {
			title: 'tms-basic-fee-template',
			icon: '',
			menu_Id: 1744,
			menuName: '收费模板查询',
      isOpenWindow: false
		}
	},{
		path: '/tms/basic/fee-template-setting',
		component: () => import('@/views/tms/basic/fee-template-setting'),
		name: 'tms-basic-fee-template-setting',
		meta: {
			title: 'tms-basic-fee-template-setting',
			icon: '',
			menu_Id: 168,
			menuName: '收费模板设置',
      isOpenWindow: false
		}
	},{
		path: '/tms/basic/freight-price',
		component: () => import('@/views/tms/basic/freight-price'),
		name: 'tms-basic-freight-price',
		meta: {
			title: 'tms-basic-freight-price',
			icon: '',
			menu_Id: 1745,
			menuName: '运费模板查询',
      isOpenWindow: false
		}
	},{
		path: '/tms/basic/freight-price-setting',
		component: () => import('@/views/tms/basic/freight-price-setting'),
		name: 'tms-basic-freight-price-setting',
		meta: {
			title: 'tms-basic-freight-price-setting',
			icon: '',
			menu_Id: 171,
			menuName: '运费模板设置',
      isOpenWindow: false
		}
	},{
		path: '/tms/service/abnormal',
		component: () => import('@/views/tms/service/abnormal'),
		name: 'tms-service-abnormal',
		meta: {
			title: 'tms-service-abnormal',
			icon: '',
			menu_Id: 1738,
			menuName: '异常件维护',
      isOpenWindow: false
		}
	},{
		path: '/tms/basic/print-sorting',
		component: () => import('@/views/tms/basic/print-sorting'),
		name: 'tms-basic-print-sorting',
		meta: {
			title: 'tms-basic-print-sorting',
			icon: '',
			menu_Id: 243,
			menuName: '分拣机打印机设置',
      isOpenWindow: false
		}
	},{
		path: '/tms/basic/pda-print',
		component: () => import('@/views/tms/basic/pda-print'),
		name: 'tms-basic-pda-print',
		meta: {
			title: 'tms-basic-pda-print',
			icon: '',
			menu_Id: 297,
			menuName: 'PDA打印机设置',
      isOpenWindow: false
		}
	},{
		path: '/tms/basic/port-template',
		component: () => import('@/views/tms/basic/port-template'),
		name: 'tms-basic-port-template',
		meta: {
			title: 'tms-basic-port-template',
			icon: '',
			menu_Id: 1753,
			menuName: '口岸打印模板设置',
      isOpenWindow: false
		}
	}]
}]
},{
	path: '/produce',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'produce',
	alwaysShow: true,
	meta: {
		title: 'produce',
		icon: 'menu-icon el-icon-yrt-cheliangyingxiangshezhi',
		menu_Id: 471,
    menuName: '生产加工',
    isOpenWindow: false
	},
	children:[{
		path: '/produce/purchase/order',
		component: () => import('@/views/produce/purchase/order'),
		name: 'produce-purchase-order',
		meta: {
			title: 'produce-purchase-order',
			icon: '',
			menu_Id: 473,
			menuName: '加工入库单',
      isOpenWindow: false
		}
	},{
		path: '/produce/sale/order-stock',
		component: () => import('@/views/produce/sale/order-stock'),
		name: 'produce-sale-order-stock',
		meta: {
			title: 'produce-sale-order-stock',
			icon: '',
			menu_Id: 1762,
			menuName: '加工出库单',
      isOpenWindow: false
		}
	},{
		path: '/produce/produce-day',
		component: () => import('@/views/produce/produce-day'),
		name: 'produce-produce-day',
		meta: {
			title: 'produce-produce-day',
			icon: '',
			menu_Id: 1845,
			menuName: '加工日报表',
      isOpenWindow: false
		}
	}]
},{
	path: 'wcs',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'wcs',
	alwaysShow: true,
	meta: {
		title: 'wcs',
		icon: 'menu-icon el-icon-yrt-wuliuguanliicon',
		menu_Id: 1849,
    menuName: 'WCS',
    isOpenWindow: false
	},
	children:[{
		path: '/wcs/task/queue',
		component: () => import('@/views/wcs/task/queue'),
		name: 'wcs-task-queue',
		meta: {
			title: 'wcs-task-queue',
			icon: 'menu-icon el-icon-yrt-wuliuguanliicon',
			menu_Id: 1850,
			menuName: '任务管理',
      isOpenWindow: false
		}
	}]
},{
	path: '/api',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'api',
	alwaysShow: true,
	meta: {
		title: 'api',
		icon: 'menu-icon el-icon-yrt-huakuai',
		menu_Id: 274,
    menuName: '接口',
    isOpenWindow: false
	},
	children:[{
	path: 'amazon',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'amazon-base',
	alwaysShow: true,
	meta: {
		title: 'amazon-base',
		icon: '',
		menu_Id: 388,
    menuName: 'Amazon',
    isOpenWindow: false
	},
	children:[{
		path: '/api/amazon/storeInfo',
		component: () => import('@/views/api/amazon/storeInfo'),
		name: 'api-amazon-storeInfo',
		meta: {
			title: 'api-amazon-storeInfo',
			icon: '',
			menu_Id: 389,
			menuName: '店铺设置',
      isOpenWindow: false
		}
	},{
		path: '/api/amazon/catalog-relation',
		component: () => import('@/views/api/amazon/catalog-relation'),
		name: 'api-amazon-catalog-relation',
		meta: {
			title: 'api-amazon-catalog-relation',
			icon: '',
			menu_Id: 323,
			menuName: '类目映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/amazon/product',
		component: () => import('@/views/api/amazon/product'),
		name: 'api-amazon-product',
		meta: {
			title: 'api-amazon-product',
			icon: '',
			menu_Id: 356,
			menuName: 'SKU映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/amazon/saleOrder',
		component: () => import('@views/api/amazon/saleOrder'),
		name: 'api-amazon-saleOrder',
		meta: {
			title: 'api-amazon-saleOrder',
			icon: '',
			menu_Id: 357,
			menuName: '订单映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/amazon/saleOrder-list',
		component: () => import('@views/api/amazon/saleOrder-list'),
		name: 'api-amazon-saleOrder-list',
		meta: {
			title: 'api-amazon-saleOrder-list',
			icon: '',
			menu_Id: 358,
			menuName: '订单明细映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/amazon/orderStatus-relation',
		component: () => import('@views/api/amazon/orderStatus-relation'),
		name: 'api-amazon-orderStatus-relation',
		meta: {
			title: 'api-amazon-orderStatus-relation',
			icon: '',
			menu_Id: 359,
			menuName: '订单状态映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/amazon/order-fulfillment',
		component: () => import('@views/api/amazon/order-fulfillment'),
		name: 'api-amazon-order-fulfillment',
		meta: {
			title: 'api-amazon-order-fulfillment',
			icon: '',
			menu_Id: 405,
			menuName: '订单配送信息推送',
      isOpenWindow: false
		}
	},{
		path: '/api/amazon/order-acknowledgement',
		component: () => import('@views/api/amazon/order-acknowledgement'),
		name: 'api-amazon-order-acknowledgement',
		meta: {
			title: 'api-amazon-order-acknowledgement',
			icon: '',
			menu_Id: 404,
			menuName: '订单确认取消推送',
      isOpenWindow: false
		}
	},{
		path: '/api/amazon/client-relation',
		component: () => import('@views/api/amazon/client-relation'),
		name: 'api-amazon-client-relation',
		meta: {
			title: 'api-amazon-client-relation',
			icon: '',
			menu_Id: 367,
			menuName: '客户映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/amazon/product-image-relation',
		component: () => import('@views/api/amazon/product-image-relation'),
		name: 'api-amazon-product-image-relation',
		meta: {
			title: 'api-amazon-product-image-relation',
			icon: '',
			menu_Id: 368,
			menuName: '图片映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/amazon/feedback',
		component: () => import('@views/api/amazon/feedback'),
		name: 'api-amazon-feedback',
		meta: {
			title: 'api-amazon-feedback',
			icon: '',
			menu_Id: 409,
			menuName: '订单评论',
      isOpenWindow: false
		}
	},{
		path: '/api/amazon/spec-value-relation',
		component: () => import('@views/api/amazon/spec-value-relation'),
		name: 'api-amazon-spec-value-relation',
		meta: {
			title: 'api-amazon-spec-value-relation',
			icon: '',
			menu_Id: 472,
			menuName: '规格映射关系',
      isOpenWindow: false
		}
	}]
},{
	path: 'ebay',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'ebay-base',
	alwaysShow: true,
	meta: {
		title: 'ebay-base',
		icon: '',
		menu_Id: 175,
    menuName: 'eBay',
    isOpenWindow: false
	},
	children:[{
		path: '/api/ebay/storeInfo',
		component: () => import('@views/api/ebay/storeInfo'),
		name: 'api-ebay-storeInfo',
		meta: {
			title: 'api-ebay-storeInfo',
			icon: '',
			menu_Id: 181,
			menuName: '店铺设置',
      isOpenWindow: false
		}
	},{
		path: '/api/ebay/site-code-type',
		component: () => import('@views/api/ebay/site-code-type'),
		name: 'api-ebay-site-code-type',
		meta: {
			title: 'api-ebay-site-code-type',
			icon: '',
			menu_Id: 402,
			menuName: 'eBay站点编码',
      isOpenWindow: false
		}
	},{
		path: '/api/ebay/category-root',
		component: () => import('@views/api/ebay/category-root'),
		name: 'api-ebay-category-root',
		meta: {
			title: 'api-ebay-category-root',
			icon: '',
			menu_Id: 403,
			menuName: '根类目管理',
      isOpenWindow: false
		}
	},{
		path: '/api/ebay/category-relation',
		component: () => import('@views/api/ebay/category-relation'),
		name: 'api-ebay-category-relation',
		meta: {
			title: 'api-ebay-category-relation',
			icon: '',
			menu_Id: 182,
			menuName: '类目映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/ebay/sku-relation',
		component: () => import('@views/api/ebay/sku-relation'),
		name: 'api-ebay-sku-relation',
		meta: {
			title: 'api-ebay-sku-relation',
			icon: '',
			menu_Id: 176,
			menuName: 'SKU映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/ebay/order-relation',
		component: () => import('@views/api/ebay/order-relation'),
		name: 'api-ebay-order-relation',
		meta: {
			title: 'api-ebay-order-relation',
			icon: '',
			menu_Id: 279,
			menuName: '订单映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/ebay/sale-sendList',
		name: 'null',
		meta: {
			title: 'null',
			icon: '',
			menu_Id: 293,
			menuName: '订单配送信息推送',
      isOpenWindow: false
		}
	},{
		path: '/api/ebay/client-relation',
		component: () => import('@views/api/ebay/client-relation'),
		name: 'api-ebay-client-relation',
		meta: {
			title: 'api-ebay-client-relation',
			icon: '',
			menu_Id: 178,
			menuName: '客户映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/ebay/message',
		component: () => import('@views/api/ebay/message'),
		name: 'api-ebay-message',
		meta: {
			title: 'api-ebay-message',
			icon: '',
			menu_Id: 353,
			menuName: '消息管理',
      isOpenWindow: false
		}
	},{
		path: '/api/ebay/sku-image-relation',
		component: () => import('@views/api/ebay/sku-image-relation'),
		name: 'api-ebay-sku-image-relation',
		meta: {
			title: 'api-ebay-sku-image-relation',
			icon: '',
			menu_Id: 351,
			menuName: 'SKU图片映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/ebay/shipment-carries',
		component: () => import('@views/api/ebay/shipment-carries'),
		name: 'api-ebay-shipment-carries',
		meta: {
			title: 'api-ebay-shipment-carries',
			icon: '',
			menu_Id: 432,
			menuName: '快递映射关系',
      isOpenWindow: false
		}
	}]
},{
	path: 'aliexpress',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'aliexpress-base',
	alwaysShow: true,
	meta: {
		title: 'aliexpress-base',
		icon: '',
		menu_Id: 174,
    menuName: '速卖通',
    isOpenWindow: false
	},
	children:[{
		path: '/api/aliexpress/storeInfo',
		component: () => import('@views/api/aliexpress/storeInfo'),
		name: 'api-aliexpress-storeInfo',
		meta: {
			title: 'api-aliexpress-storeInfo',
			icon: '',
			menu_Id: 310,
			menuName: '店铺设置',
      isOpenWindow: false
		}
	},{
		path: '/api/aliexpress/catalog-relation',
		component: () => import('@views/api/aliexpress/catalog-relation'),
		name: 'api-aliexpress-catalog-relation',
		meta: {
			title: 'api-aliexpress-catalog-relation',
			icon: '',
			menu_Id: 222,
			menuName: '类目映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/aliexpress/product',
		component: () => import('@views/api/aliexpress/product'),
		name: 'api-aliexpress-product',
		meta: {
			title: 'api-aliexpress-product',
			icon: '',
			menu_Id: 332,
			menuName: 'SKU映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/aliexpress/saleOrder',
		component: () => import('@views/api/aliexpress/saleOrder'),
		name: 'api-aliexpress-saleOrder',
		meta: {
			title: 'api-aliexpress-saleOrder',
			icon: '',
			menu_Id: 338,
			menuName: '订单映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/aliexpress/orderStatus-relation',
		component: () => import('@views/api/aliexpress/orderStatus-relation'),
		name: 'api-aliexpress-orderStatus-relation',
		meta: {
			title: 'api-aliexpress-orderStatus-relation',
			icon: '',
			menu_Id: 355,
			menuName: '订单状态映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/aliexpress/order-fulfillment',
		component: () => import('@views/api/aliexpress/order-fulfillment'),
		name: 'api-aliexpress-order-fulfillment',
		meta: {
			title: 'api-aliexpress-order-fulfillment',
			icon: '',
			menu_Id: 349,
			menuName: '订单配送信息推送',
      isOpenWindow: false
		}
	},{
		path: '/api/aliexpress/client-relation',
		component: () => import('@views/api/aliexpress/client-relation'),
		name: 'api-aliexpress-client-relation',
		meta: {
			title: 'api-aliexpress-client-relation',
			icon: '',
			menu_Id: 224,
			menuName: '客户映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/aliexpress/express-relation',
		component: () => import('@views/api/aliexpress/express-relation'),
		name: 'api-aliexpress-express-relation',
		meta: {
			title: 'api-aliexpress-express-relation',
			icon: '',
			menu_Id: 264,
			menuName: '快递映射关系',
      isOpenWindow: false
		}
	},{
		path: '/api/aliexpress/order-msg',
		component: () => import('@views/api/aliexpress/order-msg'),
		name: 'api-aliexpress-order-msg',
		meta: {
			title: 'api-aliexpress-order-msg',
			icon: '',
			menu_Id: 291,
			menuName: '订单留言',
      isOpenWindow: false
		}
	},{
		path: '/api/aliexpress/message',
		component: () => import('@views/api/aliexpress/message'),
		name: 'api-aliexpress-message',
		meta: {
			title: 'api-aliexpress-message',
			icon: '',
			menu_Id: 362,
			menuName: '站内信',
      isOpenWindow: false
		}
	},{
		path: '/api/aliexpress/catalog-attribute',
		component: () => import('@views/api/aliexpress/catalog-attribute'),
		name: 'api-aliexpress-catalog-attribute',
		meta: {
			title: 'api-aliexpress-catalog-attribute',
			icon: '',
			menu_Id: 363,
			menuName: '速卖通类目属性项',
      isOpenWindow: false
		}
	},{
		path: '/api/aliexpress/picture-relation',
		component: () => import('@views/api/aliexpress/picture-relation'),
		name: 'api-aliexpress-picture-relation',
		meta: {
			title: 'api-aliexpress-picture-relation',
			icon: '',
			menu_Id: 365,
			menuName: '速卖通图片映射关系',
      isOpenWindow: false
		}
	}]
},{
	path: 'internal',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'api-internal',
	alwaysShow: true,
	meta: {
		title: 'api-internal',
		icon: '',
		menu_Id: 289,
    menuName: '国内物流',
    isOpenWindow: false
	},
	children:[{
		path: '/api/internal/express-order-route',
		component: () => import('@/views/api/internal/express-order-route'),
		name: 'api-internal-express-order-route',
		meta: {
			title: 'api-internal-express-order-route',
			icon: '',
			menu_Id: 270,
			menuName: '快递路由查询',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/yt-base-info',
		component: () => import('@/views/api/internal/yt-base-info'),
		name: 'api-internal-yt-base-info',
		meta: {
			title: 'api-internal-yt-base-info',
			icon: '',
			menu_Id: 1557,
			menuName: '圆通账号设置',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/yt-order',
		component: () => import('@/views/api/internal/yt-order'),
		name: 'api-internal-yt-order',
		meta: {
			title: 'api-internal-yt-order',
			icon: '',
			menu_Id: 1545,
			menuName: '圆通单号获取',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/zt-base-info',
		component: () => import('@/views/api/internal/yt-base-info'),
		name: 'api-internal-zt-base-info',
		meta: {
			title: 'api-internal-zt-base-info',
			icon: '',
			menu_Id: 1558,
			menuName: '中通账号设置',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/zt-order',
		component: () => import('@/views/api/internal/zt-order'),
		name: '/api/internal/zt-order',
		meta: {
			title: '/api/internal/zt-order',
			icon: '',
			menu_Id: 1543,
			menuName: '中通单号获取',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/sf-base-info',
		component: () => import('@/views/api/internal/sf-base-info'),
		name: 'api-internal-sf-base-info',
		meta: {
			title: 'api-internal-sf-base-info',
			icon: '',
			menu_Id: 1556,
			menuName: '顺丰账号设置',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/sf-order',
		component: () => import('@/views/api/internal/sf-order'),
		name: 'api-internal-sf-order',
		meta: {
			title: 'api-internal-sf-order',
			icon: '',
			menu_Id: 1526,
			menuName: '顺丰单号获取',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/sf-order-filter',
		component: () => import('@/views/api/internal/sf-order-filter'),
		name: 'api-internal-sf-order-filter',
		meta: {
			title: 'api-internal-sf-order-filter',
			icon: '',
			menu_Id: 1544,
			menuName: '顺丰人工筛单',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/kd100-order-route',
		component: () => import('@/views/api/internal/kd100-order-route'),
		name: 'api-internal-kd100-order-route',
		meta: {
			title: 'api-internal-kd100-order-route',
			icon: '',
			menu_Id: 1575,
			menuName: '快递100',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/sto-base-info',
		component: () => import('@/views/api/internal/sto-order'),
		name: 'api-internal-sto-base-info',
		meta: {
			title: 'api-internal-sto-base-info',
			icon: '',
			menu_Id: 220,
			menuName: '申通账号设置',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/sto-order',
		component: () => import('@/views/api/internal/sto-order'),
		name: 'api-internal-sto-order',
		meta: {
			title: 'api-internal-sto-order',
			icon: '',
			menu_Id: 221,
			menuName: '申通订单推送',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/cn-base-info',
		component: () => import('@/views/api/internal/cn-base-info'),
		name: 'api-internal-cn-base-info',
		meta: {
			title: 'api-internal-cn-base-info',
			icon: '',
			menu_Id: 470,
			menuName: '菜鸟账号设置',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/cn-order',
		component: () => import('@/views/api/internal/cn-order'),
		name: 'api-internal-cn-order',
		meta: {
			title: 'api-internal-cn-order',
			icon: '',
			menu_Id: 468,
			menuName: '菜鸟运单推送',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/bsht-base-info',
		component: () => import('@/views/api/internal/bsht-base-info'),
		name: 'api-internal-bsht-base-info',
		meta: {
			title: 'api-internal-bsht-base-info',
			icon: '',
			menu_Id: 1675,
			menuName: '百世账号设置',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/bsht-order',
		component: () => import('@/views/api/internal/bsht-order'),
		name: 'api-internal-bsht-order',
		meta: {
			title: 'api-internal-bsht-order',
			icon: '',
			menu_Id: 1676,
			menuName: '百世单号获取',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/ems-order',
		component: () => import('@/views/api/internal/ems-order'),
		name: 'api-internal-ems-order',
		meta: {
			title: 'api-internal-ems-order',
			icon: '',
			menu_Id: 1755,
			menuName: 'EMS单号获取',
      isOpenWindow: false
		}
	},{
		path: '/api/internal/ldg-order',
		component: () => import('@/views/api/internal/ldg-order'),
		name: 'api-internal-ldg-order',
		meta: {
			title: 'api-internal-ldg-order',
			icon: '',
			menu_Id: 1771,
			menuName: '泉州陆地港单号获取',
      isOpenWindow: false
		}
	}]
},{
	path: 'weidian',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'weidian',
	alwaysShow: true,
	meta: {
		title: 'weidian',
		icon: '',
		menu_Id: 177,
    menuName: '微店',
    isOpenWindow: false
	},
	children:[{
		path: '/interface/weidian/basicInfo',
		component: () => import('@/views/interface/weidian/basicInfo'),
		name: 'interface-weidian-basicInfo',
		meta: {
			title: 'interface-weidian-basicInfo',
			icon: '',
			menu_Id: 1787,
			menuName: '接口配置',
      isOpenWindow: false
		}
	},{
		path: '/interface/weidian/product',
		component: () => import('@/views/interface/weidian/product'),
		name: 'interface-weidian-product',
		meta: {
			title: 'interface-weidian-product',
			icon: '',
			menu_Id: 277,
			menuName: '商品信息',
      isOpenWindow: false
		}
	},{
		path: '/interface/weidian/order',
		component: () => import('@/views/interface/weidian/order'),
		name: 'interface-weidian-order',
		meta: {
			title: 'interface-weidian-order',
			icon: '',
			menu_Id: 278,
			menuName: '订单信息',
      isOpenWindow: false
		}
	}]
},{
	path: 'guanjiapo',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'guanjiapo',
	alwaysShow: true,
	meta: {
		title: 'guanjiapo',
		icon: '',
		menu_Id: 209,
    menuName: '管家婆',
    isOpenWindow: false
	},
	children:[{
		path: '/inbound/purchase/order-gjp',
		component: () => import('@/views/inbound/purchase/order-gjp'),
		name: 'inbound-purchase-order-gjp',
		meta: {
			title: 'inbound-purchase-order-gjp',
			icon: '',
			menu_Id: 210,
			menuName: '收货入库推送',
      isOpenWindow: false
		}
	},{
		path: '/outbound/sale/order-gjp',
		component: () => import('@/views/outbound/sale/order-gjp'),
		name: 'outbound-sale-order-gjp',
		meta: {
			title: 'outbound-sale-order-gjp',
			icon: '',
			menu_Id: 211,
			menuName: '出库单推送',
      isOpenWindow: false
		}
	},{
		path: '/inbound/purchase/enter-gjp',
		component: () => import('@/views/inbound/purchase/enter-gjp'),
		name: 'inbound-purchase-enter-gjp',
		meta: {
			title: 'inbound-purchase-enter-gjp',
			icon: '',
			menu_Id: 214,
			menuName: '入库单推送',
      isOpenWindow: false
		}
	},{
		path: '/outbound/sale/outer-gjp',
		component: () => import('@/views/outbound/sale/outer-gjp'),
		name: 'outbound-sale-outer-gjp',
		meta: {
			title: 'outbound-sale-outer-gjp',
			icon: '',
			menu_Id: 208,
			menuName: '打包单推送',
      isOpenWindow: false
		}
	},{
		path: '/outbound/service/exchange-gjp',
		component: () => import('@/views/outbound/service/exchange-gjp'),
		name: 'outbound-service-exchange-gjp',
		meta: {
			title: 'outbound-service-exchange-gjp',
			icon: '',
			menu_Id: 1689,
			menuName: '销售换货推送',
      isOpenWindow: false
		}
	},{
		path: '/storage/check/profit-loss-gjp',
		component: () => import('@/views/storage/check/profit-loss-gjp'),
		name: 'storage-check-profit-loss-gjp',
		meta: {
			title: 'storage-check-profit-loss-gjp',
			icon: '',
			menu_Id: 1701,
			menuName: '盘点盈亏单推送',
      isOpenWindow: false
		}
	},{
		path: '/storage/operation/storage-adjust-gjp',
		component: () => import('@/views/storage/operation/storage-adjust-gjp'),
		name: 'storage-operation-storage-adjust-gjp',
		meta: {
			title: 'storage-operation-storage-adjust-gjp',
			icon: '',
			menu_Id: 1705,
			menuName: '库存调整单推送',
      isOpenWindow: false
		}
	},{
		path: '/outbound/service/return-gjp',
		component: () => import('@/views/outbound/service/return-gjp'),
		name: '/outbound/service/return-gjp',
		meta: {
			title: '/outbound/service/return-gjp',
			icon: '',
			menu_Id: 1706,
			menuName: '销售退货推送',
      isOpenWindow: false
		}
	}]
},{
	path: 'wulian',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'wulian',
	alwaysShow: true,
	meta: {
		title: 'wulian',
		icon: '',
		menu_Id: 1770,
    menuName: '浙江物联',
    isOpenWindow: false
	},
	children:[{
		path: '/inbound/purchase/enter-wulian',
		component: () => import('@/views/inbound/purchase/enter-wulian'),
		name: 'inbound-purchase-enter-wulian',
		meta: {
			title: 'inbound-purchase-enter-wulian',
			icon: '',
			menu_Id: 1775,
			menuName: '入库单推送',
      isOpenWindow: false
		}
	},{
		path: '/storage/check/profit-loss-wulian',
		component: () => import('@/views/storage/check/profit-loss-wulian'),
		name: 'storage-check-profitlosswulian',
		meta: {
			title: 'storage-check-profitlosswulian',
			icon: '',
			menu_Id: 1847,
			menuName: '盘点盈亏单推送',
      isOpenWindow: false
		}
	},{
		path: '/outbound/sale/outer-wulian',
		component: () => import('@/views/outbound/sale/outer-wulian'),
		name: 'outbound-sale-outer-wulian',
		meta: {
			title: 'outbound-sale-outer-wulian',
			icon: '',
			menu_Id: 1862,
			menuName: '出库单推送',
      isOpenWindow: false
		}
	}]
}]
},{
	path: '/crm',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'crm',
	alwaysShow: true,
	meta: {
		title: 'crm',
		icon: 'menu-icon el-icon-yrt-ai-connection',
		menu_Id: 294,
    menuName: 'CRM',
    isOpenWindow: false
	},
	children:[{
	path: 'client',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'crm-client',
	alwaysShow: true,
	meta: {
		title: 'crm-client',
		icon: '',
		menu_Id: 167,
    menuName: '客户管理',
    isOpenWindow: false
	},
	children:[{
		path: '/crm/base/corperation',
		component: () => import('@/views/crm/base/corperation'),
		name: 'crm-base-corperation',
		meta: {
			title: 'crm-base-corperation',
			icon: '',
			menu_Id: 1582,
			menuName: '公司管理',
      isOpenWindow: false
		}
	},{
		path: '/crm/base/client',
		component: () => import('@/views/crm/base/client'),
		name: 'crm-base-client',
		meta: {
			title: 'crm-base-client',
			icon: '',
			menu_Id: 255,
			menuName: '客户信息',
      isOpenWindow: false
		}
	},{
		path: '/crm/base/linker',
		component: () => import('@/views/crm/base/linker'),
		name: 'crm-base-linker',
		meta: {
			title: 'crm-base-linker',
			icon: '',
			menu_Id: 296,
			menuName: '联系人管理',
      isOpenWindow: false
		}
	},{
		path: '/crm/base/address',
		component: () => import('@/views/crm/base/address'),
		name: 'crm-base-address',
		meta: {
			title: 'crm-base-address',
			icon: '',
			menu_Id: 390,
			menuName: '客户地址管理',
      isOpenWindow: false
		}
	},{
		path: '/crm/base/client-coupon',
		component: () => import('@/views/crm/base/client-coupon'),
		name: 'crm-base-client-coupon',
		meta: {
			title: 'crm-base-client-coupon',
			icon: '',
			menu_Id: 392,
			menuName: '客户优惠券',
      isOpenWindow: false
		}
	},{
		path: '/crm/base/client-tob',
		component: () => import('@/views/crm/base/client-tob'),
		name: 'crm-base-client-tob',
		meta: {
			title: 'crm-base-client-tob',
			icon: '',
			menu_Id: 1865,
			menuName: 'TOB客户信息',
      isOpenWindow: false
		}
	},{
		path: '/crm/base/client-b',
		component: () => import('@/views/crm/base/client-b'),
		name: 'crm-base-client-b',
		meta: {
			title: 'crm-base-client-b',
			icon: '',
			menu_Id: 1866,
			menuName: '小B客户信息',
      isOpenWindow: false
		}
	},{
		path: '/crm/base/client-toc',
		component: () => import('@/views/crm/base/client-toc'),
		name: 'crm-base-client-toc',
		meta: {
			title: 'crm-base-client-toc',
			icon: '',
			menu_Id: 1867,
			menuName: 'TOC客户信息',
      isOpenWindow: false
		}
	},{
		path: '/crm/base/feedback',
		component: () => import('@/views/crm/base/feedback'),
		name: 'crm-base-feedback',
		meta: {
			title: 'crm-base-feedback',
			icon: '',
			menu_Id: 391,
			menuName: '意见反馈',
      isOpenWindow: false
		}
	}]
},{
	path: 'app',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'crm-app',
	alwaysShow: true,
	meta: {
		title: 'crm-app',
		icon: '',
		menu_Id: 1790,
    menuName: 'App',
    isOpenWindow: false
	},
	children:[{
		path: '/crm/app/productApply',
		component: () => import('@/views/crm/app/productApply'),
		name: 'crm-app-productApply',
		meta: {
			title: 'crm-app-productApply',
			icon: 'menu-icon el-icon-yrt-ai-connection',
			menu_Id: 1791,
			menuName: '商品申请审核',
      isOpenWindow: false
		}
	},{
		path: '/crm/app/feedacbk',
		component: () => import('@/views/crm/app/feedacbk'),
		name: 'crm-app-feedacbk',
		meta: {
			title: 'crm-app-feedacbk',
			icon: 'menu-icon el-icon-yrt-ai-connection',
			menu_Id: 1792,
			menuName: '客户端反馈',
      isOpenWindow: false
		}
	},{
		path: '/crm/app/goodsView',
		component: () => import('@/views/crm/app/goodsView'),
		name: 'crm-app-goodsView',
		meta: {
			title: 'crm-app-goodsView',
			icon: 'menu-icon el-icon-yrt-ai-connection',
			menu_Id: 1793,
			menuName: '仓库实物巡检',
      isOpenWindow: false
		}
	},{
		path: '/crm/app/address',
		component: () => import('@/views/crm/app/address'),
		name: 'crm-app-address',
		meta: {
			title: 'crm-app-address',
			icon: 'menu-icon el-icon-yrt-ai-connection',
			menu_Id: 1794,
			menuName: '地址',
      isOpenWindow: false
		}
	}]
}]
},{
	path: 'out-in-trade',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'out-in-trade',
	alwaysShow: true,
	meta: {
		title: 'out-in-trade',
		icon: 'menu-icon el-icon-yrt-pandian',
		menu_Id: 1632,
    menuName: '进出口贸易',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/biz/exchange',
		component: () => import('@/views/stat/biz/exchange'),
		name: 'stat-biz-exchange',
		meta: {
			title: 'stat-biz-exchange',
			icon: '',
			menu_Id: 1633,
			menuName: '询价管理',
      isOpenWindow: false
		}
	},{
		path: '/stat/biz/exchange-month',
		component: () => import('@/views/stat/biz/exchange-month'),
		name: 'stat-biz-exchange-month',
		meta: {
			title: 'stat-biz-exchange-month',
			icon: '',
			menu_Id: 1634,
			menuName: '询价分析月报表',
      isOpenWindow: false
		}
	},{
		path: '/stat/biz/exchange-quarter',
		component: () => import('@/views/stat/biz/exchange-quarter'),
		name: 'stat-biz-exchange-quarter',
		meta: {
			title: 'stat-biz-exchange-quarter',
			icon: '',
			menu_Id: 1635,
			menuName: '询价分析季报表',
      isOpenWindow: false
		}
	},{
		path: '/stat/biz/exchange-year',
		component: () => import('@/views/stat/biz/exchange-year'),
		name: 'stat-biz-exchange-year',
		meta: {
			title: 'stat-biz-exchange-year',
			icon: '',
			menu_Id: 1636,
			menuName: '询价分析年报表',
      isOpenWindow: false
		}
	},{
		path: '/stat/biz/visualization-exchange-month',
		component: () => import('@/views/stat/biz/visualization-exchange-month'),
		name: 'stat-biz-visualization-exchange-month',
		meta: {
			title: 'stat-biz-visualization-exchange-month',
			icon: '',
			menu_Id: 1637,
			menuName: '询价折线图-月',
      isOpenWindow: false
		}
	},{
		path: '/stat/biz/visualization-exchange-quarter',
		component: () => import('@/views/stat/biz/visualization-exchange-quarter'),
		name: 'stat-biz-visualization-exchange-quarter',
		meta: {
			title: 'stat-biz-visualization-exchange-quarter',
			icon: '',
			menu_Id: 1639,
			menuName: '询价折线图-季',
      isOpenWindow: false
		}
	},{
		path: '/stat/biz/visualization-exchange-year',
		component: () => import('@/views/stat/biz/visualization-exchange-year'),
		name: 'stat-biz-visualization-exchange-year',
		meta: {
			title: 'stat-biz-visualization-exchange-year',
			icon: '',
			menu_Id: 1684,
			menuName: '询价折线图-年',
      isOpenWindow: false
		}
	}]
},{
	path: '/stat',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'stat',
	alwaysShow: true,
	meta: {
		title: 'stat',
		icon: 'menu-icon el-icon-yrt-tongji',
		menu_Id: 252,
    menuName: '统计',
    isOpenWindow: false
	},
	children:[{
	path: 'stat-base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'stat-base',
	alwaysShow: true,
	meta: {
		title: 'stat-base',
		icon: '',
		menu_Id: 1566,
    menuName: '数据监控',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/inventory/early',
		component: () => import('@/views/stat/inventory/early'),
		name: 'v-shelflife-early-warning',
		meta: {
			title: 'v-shelflife-early-warning',
			icon: '',
			menu_Id: 1570,
			menuName: '保质期预警',
      isOpenWindow: false
		}
	},{
		path: '/stat/inventory/warning',
		component: () => import('@/views/stat/inventory/warning'),
		name: 'v-old-library-early-warning',
		meta: {
			title: 'v-old-library-early-warning',
			icon: '',
			menu_Id: 1571,
			menuName: '库龄预警',
      isOpenWindow: false
		}
	},{
		path: '/stat/inventory/lower',
		component: () => import('@/views/stat/inventory/lower'),
		name: 'v-base-product-position-lower',
		meta: {
			title: 'v-base-product-position-lower',
			icon: '',
			menu_Id: 1720,
			menuName: '货位最低库存预警',
      isOpenWindow: false
		}
	}]
},{
	path: 'stat-summary',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'stat-summary',
	alwaysShow: true,
	meta: {
		title: 'stat-summary',
		icon: '',
		menu_Id: 495,
    menuName: '汇总统计',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/summary/typesummary',
		component: () => import('@/views/stat/summary/typesummary'),
		name: 'stat-summary-typesummary',
		meta: {
			title: 'stat-summary-typesummary',
			icon: '',
			menu_Id: 1815,
			menuName: '类别明细汇总统计',
      isOpenWindow: false
		}
	}]
},{
	path: 'stat-query',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'stat-query',
	alwaysShow: true,
	meta: {
		title: 'stat-query',
		icon: '',
		menu_Id: 1827,
    menuName: '分类查询统计',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/query/typeclassifcount',
		component: () => import('@/views/stat/query/typeclassifcount'),
		name: 'stat-query-typeclassifcount',
		meta: {
			title: 'stat-query-typeclassifcount',
			icon: '',
			menu_Id: 1828,
			menuName: '分类统计柱状图',
      isOpenWindow: false
		}
	}]
},{
	path: 'stat-dailyquery',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'stat-dailyquery',
	alwaysShow: true,
	meta: {
		title: 'stat-dailyquery',
		icon: '',
		menu_Id: 1829,
    menuName: '每日分类查询统计',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/dailyquery/dailyinventoryclassifi',
		component: () => import('@/views/stat/dailyquery/dailyinventoryclassifi'),
		name: 'stat-dailyquery-dailyinventoryclassifi',
		meta: {
			title: 'stat-dailyquery-dailyinventoryclassifi',
			icon: '',
			menu_Id: 1830,
			menuName: '每日库存分类统计图',
      isOpenWindow: false
		}
	},{
		path: '/stat/dailyquery/dailyoutstorageclassifi',
		component: () => import('@/views/stat/dailyquery/dailyoutstorageclassifi'),
		name: 'stat-dailyquery-dailyoutstorageclassifi',
		meta: {
			title: 'stat-dailyquery-dailyoutstorageclassifi',
			icon: '',
			menu_Id: 1831,
			menuName: '每日出库分类统计图',
      isOpenWindow: false
		}
	},{
		path: '/stat/dailyquery/dailystorageclassifi',
		component: () => import('@/views/stat/dailyquery/dailystorageclassifi'),
		name: 'stat-dailyquery-dailystorageclassifi',
		meta: {
			title: 'stat-dailyquery-dailystorageclassifi',
			icon: '',
			menu_Id: 1832,
			menuName: '每日入库分类统计图',
      isOpenWindow: false
		}
	}]
},{
	path: 'stat-receive-base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'stat-receive-base',
	alwaysShow: true,
	meta: {
		title: 'stat-receive-base',
		icon: '',
		menu_Id: 108,
    menuName: '收货查询',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/receive/vpurchase-order-list',
		component: () => import('@/views/stat/receive/vpurchase-order-list'),
		name: 'vpurchase-order-list',
		meta: {
			title: 'vpurchase-order-list',
			icon: '',
			menu_Id: 12,
			menuName: '预到货明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-enter-list',
		component: () => import('@/views/stat/receive/vpurchase-enter-list'),
		name: 'vpurchase-enter-list',
		meta: {
			title: 'vpurchase-enter-list',
			icon: '',
			menu_Id: 107,
			menuName: '入库明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vtms-quotation-list',
		component: () => import('@/views/stat/receive/vtms-quotation-list'),
		name: 'vtms-quotation-list',
		meta: {
			title: 'vtms-quotation-list',
			icon: '',
			menu_Id: 448,
			menuName: '入库计划明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-shelve-list',
		component: () => import('@/views/stat/receive/vpurchase-shelve-list'),
		name: 'stat-receive-vpurchase-shelve-list',
		meta: {
			title: 'stat-receive-vpurchase-shelve-list',
			icon: '',
			menu_Id: 1602,
			menuName: '上架明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-enterlist-provider',
		component: () => import('@/views/stat/receive/vpurchase-enterlist-provider'),
		name: 'vpurchase-enterlist-provider',
		meta: {
			title: 'vpurchase-enterlist-provider',
			icon: '',
			menu_Id: 164,
			menuName: '供应商进货统计',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-enterlist-product',
		component: () => import('@/views/stat/receive/vpurchase-enterlist-product'),
		name: 'vpurchase-enterlist-product',
		meta: {
			title: 'vpurchase-enterlist-product',
			icon: '',
			menu_Id: 109,
			menuName: '商品进货统计',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-order-arrived',
		component: () => import('@/views/stat/receive/vpurchase-order-arrived'),
		name: 'stat-receive-vpurchase-order-arrived',
		meta: {
			title: 'stat-receive-vpurchase-order-arrived',
			icon: '',
			menu_Id: 165,
			menuName: '预到货单到期报警',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-enterList-report',
		component: () => import('@/views/stat/receive/vpurchase-enterList-report'),
		name: 'vpurchase-enterList-report',
		meta: {
			title: 'vpurchase-enterList-report',
			icon: '',
			menu_Id: 478,
			menuName: '收货入库报表',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-orderList-report',
		component: () => import('@/views/stat/receive/vpurchase-orderList-report'),
		name: 'stat-receive-vpurchase-orderList-report',
		meta: {
			title: 'stat-receive-vpurchase-orderList-report',
			icon: '',
			menu_Id: 480,
			menuName: '预到货单查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-return-list',
		component: () => import('@/views/stat/receive/vpurchase-return-list'),
		name: 'vpurchase-return-list',
		meta: {
			title: 'vpurchase-return-list',
			icon: '',
			menu_Id: 1548,
			menuName: '收货退货查询',
      isOpenWindow: false
		}
	}]
},{
	path: 'stat-out-base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'stat-out-base',
	alwaysShow: true,
	meta: {
		title: 'stat-out-base',
		icon: '',
		menu_Id: 120,
    menuName: '出库查询',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/out/vsale-outer-list',
		component: () => import('@/views/stat/out/vsale-outer-list'),
		name: 'vsale-outer-list',
		meta: {
			title: 'vsale-outer-list',
			icon: '',
			menu_Id: 122,
			menuName: '出库打包明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-outer-case',
		component: () => import('@/views/stat/out/vsale-outer-case'),
		name: 'vsale-outer-case',
		meta: {
			title: 'vsale-outer-case',
			icon: '',
			menu_Id: 123,
			menuName: '打包装箱查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-orderlist-client',
		component: () => import('@/views/stat/out/vsale-orderlist-client'),
		name: 'vsale-orderlist-client',
		meta: {
			title: 'vsale-orderlist-client',
			icon: '',
			menu_Id: 348,
			menuName: '客户销售查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-order-list',
		component: () => import('@/views/stat/out/vsale-order-list'),
		name: 'vsale-order-list',
		meta: {
			title: 'vsale-order-list',
			icon: '',
			menu_Id: 121,
			menuName: '出库订单明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-sendlist-report',
		component: () => import('@/views/stat/out/vsale-sendlist-report'),
		name: 'vsale-sendlist-report',
		meta: {
			title: 'vsale-sendlist-report',
			icon: '',
			menu_Id: 481,
			menuName: '出货报表',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-order-plan-detail',
		component: () => import('@/views/stat/out/vsale-order-plan-detail'),
		name: 'vsale-order-plan-detail',
		meta: {
			title: 'vsale-order-plan-detail',
			icon: '',
			menu_Id: 482,
			menuName: '出库计划明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-return-list',
		component: () => import('@/views/stat/out/vsale-return-list'),
		name: 'vsale-return-list',
		meta: {
			title: 'vsale-return-list',
			icon: '',
			menu_Id: 1551,
			menuName: '出库单退货查询',
      isOpenWindow: false
		}
	},{
		path: '/outbound/require/goods-details',
		component: () => import('@/views/outbound/require/goods-details'),
		name: 'outbound-require-goods-details',
		meta: {
			title: 'outbound-require-goods-details',
			icon: '',
			menu_Id: 1555,
			menuName: '要货明细查询',
      isOpenWindow: false
		}
	},{
		path: '/outbound/require/allocation-details',
		component: () => import('@/views/outbound/require/allocation-details'),
		name: 'outbound-require-allocation-details',
		meta: {
			title: 'outbound-require-allocation-details',
			icon: '',
			menu_Id: 183,
			menuName: '要货调拨明细查询',
      isOpenWindow: false
		}
	}]
},{
	path: 'stat-storage-base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'stat-storage-base',
	alwaysShow: true,
	meta: {
		title: 'stat-storage-base',
		icon: '',
		menu_Id: 1533,
    menuName: '库存统计分析',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/storage/xstat-storageday',
		component: () => import('@/views/stat/storage/xstat-storageday'),
		name: 'xstat-storageday',
		meta: {
			title: 'xstat-storageday',
			icon: '',
			menu_Id: 216,
			menuName: '库存统计',
      isOpenWindow: false
		}
	},{
		path: '/stat/storage/stat-storageday',
		component: () => import('@/views/stat/storage/stat-storageday'),
		name: 'stat-storageday',
		meta: {
			title: 'stat-storageday',
			icon: '',
			menu_Id: 213,
			menuName: '每日商品库存查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/storage/stat-storageday-details',
		component: () => import('@/views/stat/storage/stat-storageday-details'),
		name: 'stat-storageday-details',
		meta: {
			title: 'stat-storageday-details',
			icon: '',
			menu_Id: 215,
			menuName: '每日库存明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/storage/vstorage-outer-list',
		component: () => import('@/views/stat/storage/vstorage-outer-list'),
		name: 'vstorage-outer-list',
		meta: {
			title: 'vstorage-outer-list',
			icon: '',
			menu_Id: 1535,
			menuName: '其他出库明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/storage/vstorage-enter-list',
		component: () => import('@/views/stat/storage/vstorage-enter-list'),
		name: 'vstorage-enter-list',
		meta: {
			title: 'vstorage-enter-list',
			icon: '',
			menu_Id: 1536,
			menuName: '其他入库明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/storage/xstat-storagemonth',
		component: () => import('@/views/stat/storage/xstat-storagemonth'),
		name: 'xstat-storagemonth',
		meta: {
			title: 'xstat-storagemonth',
			icon: '',
			menu_Id: 1812,
			menuName: '上个月与本月报表',
      isOpenWindow: false
		}
	}]
},{
	path: 'stat-report-base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'stat-report-base',
	alwaysShow: true,
	meta: {
		title: 'stat-report-base',
		icon: '',
		menu_Id: 1604,
    menuName: '收货统计报表',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/report/vpurchase-enter-ranking',
		component: () => import('@/views/stat/report/vpurchase-enter-ranking'),
		name: 'vpurchase-enter-ranking',
		meta: {
			title: 'vpurchase-enter-ranking',
			icon: '',
			menu_Id: 1606,
			menuName: '商品入库排名报表(年)',
      isOpenWindow: false
		}
	},{
		path: '/stat/report/vpurchase-enter-rankbymonth',
		component: () => import('@/views/stat/report/vpurchase-enter-rankbymonth'),
		name: 'vpurchase-enter-rankbymonth',
		meta: {
			title: 'vpurchase-enter-rankbymonth',
			icon: '',
			menu_Id: 1610,
			menuName: '商品入库排名报表(月)',
      isOpenWindow: false
		}
	},{
		path: '/stat/report/vpurchase-enter-rankbyweek',
		component: () => import('@/views/stat/report/vpurchase-enter-rankbyweek'),
		name: 'vpurchase-enter-rankbyweek',
		meta: {
			title: 'vpurchase-enter-rankbyweek',
			icon: '',
			menu_Id: 1613,
			menuName: '商品入库排名报表(周)',
      isOpenWindow: false
		}
	},{
		path: '/stat/report/vpurchase-enter-rankbyday',
		component: () => import('@/views/stat/report/vpurchase-enter-rankbyday'),
		name: 'vpurchase-enter-rankbyday',
		meta: {
			title: 'vpurchase-enter-rankbyday',
			icon: '',
			menu_Id: 1614,
			menuName: '商品入库排名报表(天)',
      isOpenWindow: false
		}
	}]
},{
	path: 'stat-out-report-base',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'stat-out-report-base',
	alwaysShow: true,
	meta: {
		title: 'stat-out-report-base',
		icon: '',
		menu_Id: 1605,
    menuName: '出库统计报表',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/report-out/vsale-ranking',
		component: () => import('@/views/stat/report-out/vsale-ranking'),
		name: 'vsale-ranking',
		meta: {
			title: 'vsale-ranking',
			icon: '',
			menu_Id: 1607,
			menuName: '商品出库排名报表(年)',
      isOpenWindow: false
		}
	},{
		path: '/stat/report-out/vsale-rankbymonth',
		component: () => import('@/views/stat/report-out/vsale-rankbymonth'),
		name: 'vsale-rankbymonth',
		meta: {
			title: 'vsale-rankbymonth',
			icon: '',
			menu_Id: 1611,
			menuName: '商品出库排名报表(月)',
      isOpenWindow: false
		}
	},{
		path: '/stat/report-out/vsale-rankbyweek',
		component: () => import('@/views/stat/report-out/vsale-rankbyweek'),
		name: 'vsale-rankbyweek',
		meta: {
			title: 'vsale-rankbyweek',
			icon: '',
			menu_Id: 1612,
			menuName: '商品出库排名报表(周)',
      isOpenWindow: false
		}
	},{
		path: '/stat/report-out/vsale-rankbyday',
		component: () => import('@/views/stat/report-out/vsale-rankbyday'),
		name: 'vsale-rankbyday',
		meta: {
			title: 'vsale-rankbyday',
			icon: '',
			menu_Id: 1615,
			menuName: '商品出库排名报表(天)',
      isOpenWindow: false
		}
	},{
		path: '/stat/report-out/vsale-orderpicking-workloadstatistics',
		component: () => import('@/views/stat/report-out/vsale-orderpicking-workloadstatistics'),
		name: 'vsale-orderpicking-workloadstatistics',
		meta: {
			title: 'vsale-orderpicking-workloadstatistics',
			icon: '',
			menu_Id: 1718,
			menuName: '拣货人员工作量统计',
      isOpenWindow: false
		}
	},{
		path: '/stat/report-out/vsale-orderpicking-list',
		component: () => import('@/views/stat/report-out/vsale-orderpicking-list'),
		name: 'vsale-orderpicking-list',
		meta: {
			title: 'vsale-orderpicking-list',
			icon: '',
			menu_Id: 1719,
			menuName: '拣货人员拣货明细',
      isOpenWindow: false
		}
	},{
		path: '/stat/storage/stat-commodity',
		component: () => import('@/views/stat/storage/stat-commodity'),
		name: 'stat-storage-stat-commodity',
		meta: {
			title: 'stat-storage-stat-commodity',
			icon: '',
			menu_Id: 1840,
			menuName: '商品出库环比',
      isOpenWindow: false
		}
	},{
		path: '/stat/storage/stat-commodityon',
		component: () => import('@/views/stat/storage/stat-commodityon'),
		name: 'stat-storage-stat-commodityon',
		meta: {
			title: 'stat-storage-stat-commodityon',
			icon: '',
			menu_Id: 1841,
			menuName: '商品出库同比',
      isOpenWindow: false
		}
	}]
}]
},{
	path: '/finance',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'finance',
	alwaysShow: true,
	meta: {
		title: 'finance',
		icon: 'menu-icon  el-icon-yrt-youxianzijin',
		menu_Id: 1692,
    menuName: '财务',
    isOpenWindow: false
	},
	children:[{
	path: 'performance',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'performance',
	alwaysShow: true,
	meta: {
		title: 'performance',
		icon: '',
		menu_Id: 1618,
    menuName: '效绩考核',
    isOpenWindow: false
	},
	children:[{
		path: '/finance/performance/item',
		component: () => import('@/views/finance/performance/item'),
		name: 'finance-performance-item',
		meta: {
			title: 'finance-performance-item',
			icon: '',
			menu_Id: 1620,
			menuName: '考核科目',
      isOpenWindow: false
		}
	},{
		path: '/finance/performance/template',
		component: () => import('@/views/finance/performance/template'),
		name: 'finance-performance-template',
		meta: {
			title: 'finance-performance-template',
			icon: '',
			menu_Id: 1621,
			menuName: '考核模板',
      isOpenWindow: false
		}
	},{
		path: '/finance/performance/flow',
		component: () => import('@/views/finance/performance/flow'),
		name: 'finance-performance-flow',
		meta: {
			title: 'finance-performance-flow',
			icon: '',
			menu_Id: 1767,
			menuName: '人员绩效流水',
      isOpenWindow: false
		}
	},{
		path: '/finance/performance/performance-day',
		component: () => import('@/views/finance/performance/performance-day'),
		name: 'finance-performance-performance-day',
		meta: {
			title: 'finance-performance-performance-day',
			icon: '',
			menu_Id: 1842,
			menuName: '绩效日报表',
      isOpenWindow: false
		}
	},{
		path: '/finance/performance/performance-month',
		component: () => import('@/views/finance/performance/performance-month'),
		name: 'finance-performance-performance-month',
		meta: {
			title: 'finance-performance-performance-month',
			icon: '',
			menu_Id: 1843,
			menuName: '绩效月报表',
      isOpenWindow: false
		}
	}]
},{
	path: 'collection',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'collection',
	alwaysShow: true,
	meta: {
		title: 'collection',
		icon: '',
		menu_Id: 172,
    menuName: '仓储费收',
    isOpenWindow: false
	},
	children:[{
		path: '/finance/base/storagefee',
		component: () => import('@/views/finance/base/storagefee'),
		name: 'finance-fee-base-storagefee',
		meta: {
			title: 'finance-fee-base-storagefee',
			icon: '',
			menu_Id: 1616,
			menuName: '仓储费设置',
      isOpenWindow: false
		}
	},{
		path: '/finance/base/tmsfee',
		component: () => import('@/views/finance/base/tmsfee'),
		name: 'finance-base-tmsfee',
		meta: {
			title: 'finance-base-tmsfee',
			icon: '',
			menu_Id: 1852,
			menuName: '运输费设置',
      isOpenWindow: false
		}
	},{
		path: '/finance/base/onecharge',
		component: () => import('@/views/finance/base/onecharge'),
		name: 'finance-fee-base-onecharge',
		meta: {
			title: 'finance-fee-base-onecharge',
			icon: '',
			menu_Id: 1617,
			menuName: '一次性收费项设置',
      isOpenWindow: false
		}
	},{
		path: '/finance/base/storagefeehistory',
		component: () => import('@/views/finance/base/storagefeehistory'),
		name: 'finance-fee-base-storagefeehistory',
		meta: {
			title: 'finance-fee-base-storagefeehistory',
			icon: '',
			menu_Id: 1854,
			menuName: '费用设置查询',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/feeItem',
		component: () => import('@/views/finance/fee/feeItem'),
		name: 'finance-fee-feeItem',
		meta: {
			title: 'finance-fee-feeItem',
			icon: '',
			menu_Id: 1638,
			menuName: '科目管理',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/payablebill',
		component: () => import('@/views/finance/fee/payablebill'),
		name: 'finance-fee-payablebill',
		meta: {
			title: 'finance-fee-payablebill',
			icon: '',
			menu_Id: 1785,
			menuName: '账单结算',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/statement',
		component: () => import('@/views/finance/fee/statement'),
		name: 'finance-fee-statement',
		meta: {
			title: 'finance-fee-statement',
			icon: '',
			menu_Id: 1786,
			menuName: '对账单查询',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/payablebill-sel',
		component: () => import('@/views/finance/fee/payablebill-sel'),
		name: 'finance-fee-payablebill-sel',
		meta: {
			title: 'finance-fee-payablebill-sel',
			icon: '',
			menu_Id: 1844,
			menuName: '账单结算报表',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/payablebill-everyday',
		component: () => import('@/views/finance/fee/payablebill-everyday'),
		name: 'finance-fee-payablebill-everyday',
		meta: {
			title: 'finance-fee-payablebill-everyday',
			icon: '',
			menu_Id: 251,
			menuName: '每日仓储费查询',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/collection-register',
		component: () => import('@/views/finance/fee/collection-register'),
		name: 'finance-fee-collection-register',
		meta: {
			title: 'finance-fee-collection-register',
			icon: '',
			menu_Id: 1807,
			menuName: '提货单位收款登记',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/vfee-collection-register-list',
		component: () => import('@/views/finance/fee/vfee-collection-register-list'),
		name: 'finance-vfee-collection-register-list',
		meta: {
			title: 'finance-vfee-collection-register-list',
			icon: '',
			menu_Id: 1822,
			menuName: '提货单位收款明细查询',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/tmspayablebill',
		component: () => import('@/views/finance/fee/tmspayablebill'),
		name: 'finance-fee-tmspayablebill',
		meta: {
			title: 'finance-fee-tmspayablebill',
			icon: '',
			menu_Id: 1839,
			menuName: '车辆费用账单',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/collection-customer',
		component: () => import('@/views/finance/fee/collection-customer'),
		name: 'finance-fee-collection-customer',
		meta: {
			title: 'finance-fee-collection-customer',
			icon: '',
			menu_Id: 1808,
			menuName: '客户收款登记',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/vfee-collection-customer-detail',
		component: () => import('@/views/finance/fee/vfee-collection-customer-detail'),
		name: 'finance-fee-vfee-collection-customer-detail',
		meta: {
			title: 'finance-fee-vfee-collection-customer-detail',
			icon: '',
			menu_Id: 1810,
			menuName: '客户收款登明细查询',
      isOpenWindow: false
		}
	}]
},{
	path: 'finance-basic',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'finance-basic',
	alwaysShow: true,
	meta: {
		title: 'finance-basic',
		icon: '',
		menu_Id: 1693,
    menuName: '基础信息',
    isOpenWindow: false
	},
	children:[{
		path: '/finance/base/finance-bankaccount',
		component: () => import('@/views/finance/base/finance-bankaccount'),
		name: 'finance-base-finance-bankaccount',
		meta: {
			title: 'finance-base-finance-bankaccount',
			icon: '',
			menu_Id: 1696,
			menuName: '银行账户',
      isOpenWindow: false
		}
	},{
		path: '/finance/biz/finance-bankbiz',
		component: () => import('@/views/finance/biz/finance-bankbiz'),
		name: 'finance-biz-finance-bankbiz',
		meta: {
			title: 'finance-biz-finance-bankbiz',
			icon: '',
			menu_Id: 1697,
			menuName: '账户间存取款',
      isOpenWindow: false
		}
	},{
		path: '/finance/base/finance-flow',
		component: () => import('@/views/finance/base/finance-flow'),
		name: 'finance-base-finance-flow',
		meta: {
			title: 'finance-base-finance-flow',
			icon: '',
			menu_Id: 1698,
			menuName: '账户流水',
      isOpenWindow: false
		}
	},{
		path: '/finance/biz/finance-accountcheck',
		component: () => import('@/views/finance/biz/finance-accountcheck'),
		name: 'finance-biz-finance-accountcheck',
		meta: {
			title: 'finance-biz-finance-accountcheck',
			icon: '',
			menu_Id: 1699,
			menuName: '账户调整',
      isOpenWindow: false
		}
	}]
},{
	path: 'finance-stat',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'finance-stat',
	alwaysShow: true,
	meta: {
		title: 'finance-stat',
		icon: '',
		menu_Id: 1583,
    menuName: '统计报表',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/storage/in-out',
		component: () => import('@/views/stat/storage/in-out'),
		name: 'stat-storage-in-out',
		meta: {
			title: 'stat-storage-in-out',
			icon: '',
			menu_Id: 1619,
			menuName: '每月出入库统计表',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/collection-register-stat',
		component: () => import('@/views/finance/fee/collection-register-stat'),
		name: 'finance-fee-collection-register-stat',
		meta: {
			title: 'finance-fee-collection-register-stat',
			icon: '',
			menu_Id: 1585,
			menuName: '提货单位费用统计表',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/consignor-fee-stat',
		component: () => import('@/views/finance/fee/consignor-fee-stat'),
		name: 'finance-fee-consignor-fee-stat',
		meta: {
			title: 'finance-fee-consignor-fee-stat',
			icon: '',
			menu_Id: 1578,
			menuName: '货主费用统计表',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/finance-end-month',
		component: () => import('@/views/finance/fee/finance-end-month'),
		name: 'finance-fee-finance-end-month',
		meta: {
			title: 'finance-fee-finance-end-month',
			icon: '',
			menu_Id: 1809,
			menuName: '财务月末统计表',
      isOpenWindow: false
		}
	},{
		path: '/storage/stat/vstat-inout',
		component: () => import('@/views/storage/stat/vstat-inout'),
		name: 'storage-stat-vstat-inout',
		meta: {
			title: 'storage-stat-vstat-inout',
			icon: '',
			menu_Id: 1811,
			menuName: '装卸统计表',
      isOpenWindow: false
		}
	},{
		path: '/stat/storage/vstat-consignortotal',
		component: () => import('@/views/stat/storage/vstat-consignortotal'),
		name: 'stat-storage-vstat-consignortotal',
		meta: {
			title: 'stat-storage-vstat-consignortotal',
			icon: '',
			menu_Id: 1823,
			menuName: '货主库存统计',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/vfee-payablebilldetail-producttotal',
		component: () => import('@/views/finance/fee/vfee-payablebilldetail-producttotal'),
		name: 'finance-fee-vfee-payablebilldetail-producttotal',
		meta: {
			title: 'finance-fee-vfee-payablebilldetail-producttotal',
			icon: '',
			menu_Id: 1824,
			menuName: '费用总计',
      isOpenWindow: false
		}
	}]
},{
	path: 'finance-receive-payout',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'finance-receive-payout',
	alwaysShow: true,
	meta: {
		title: 'finance-receive-payout',
		icon: '',
		menu_Id: 1694,
    menuName: '收付款',
    isOpenWindow: false
	},
	children:[{
		path: '/finance/pay/finance-payout',
		component: () => import('@/views/finance/pay/finance-payout'),
		name: 'finance-pay-finance-payout',
		meta: {
			title: 'finance-pay-finance-payout',
			icon: '',
			menu_Id: 1703,
			menuName: '付款单',
      isOpenWindow: false
		}
	},{
		path: '/finance/receive/finance-receive',
		component: () => import('@/views/finance/receive/finance-receive'),
		name: 'finance-receive-finance-receive',
		meta: {
			title: 'finance-receive-finance-receive',
			icon: '',
			menu_Id: 1704,
			menuName: '收款单',
      isOpenWindow: false
		}
	}]
},{
	path: 'fee',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'fee',
	alwaysShow: true,
	meta: {
		title: 'fee',
		icon: '',
		menu_Id: 1769,
    menuName: 'TMS绩效考核',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/fee/vtms-waybillfee',
		component: () => import('@/views/tms/fee/vtms-waybillfee'),
		name: 'tms-fee-vwaybillfee',
		meta: {
			title: 'tms-fee-vwaybillfee',
			icon: '',
			menu_Id: 1623,
			menuName: 'TMS费用明细查询',
      isOpenWindow: false
		}
	},{
		path: '/tms/fee/ratetemplate',
		component: () => import('@/views/tms/fee/ratetemplate'),
		name: 'tms-fee-ratetemplate',
		meta: {
			title: 'tms-fee-ratetemplate',
			icon: '',
			menu_Id: 1622,
			menuName: '费率模板',
      isOpenWindow: false
		}
	},{
		path: '/tms/fee/rateItem',
		component: () => import('@/views/tms/fee/rateItem'),
		name: 'tms-fee-rateItem',
		meta: {
			title: 'tms-fee-rateItem',
			icon: '',
			menu_Id: 1804,
			menuName: '费率科目',
      isOpenWindow: false
		}
	}]
}]
},{
	path: '/sys',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'sys',
	alwaysShow: true,
	meta: {
		title: 'sys',
		icon: 'menu-icon el-icon-yrt-shezhi',
		menu_Id: 1,
    menuName: '系统',
    isOpenWindow: false
	},
	children:[{
	path: 'basic',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'basic',
	alwaysShow: true,
	meta: {
		title: 'basic',
		icon: '',
		menu_Id: 253,
    menuName: '基础信息',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/basic/expresscorp',
		component: () => import('@/views/sys/basic/expresscorp'),
		name: 'sys-basic-expresscorp',
		meta: {
			title: 'sys-basic-expresscorp',
			icon: '',
			menu_Id: 232,
			menuName: '快递管理',
      isOpenWindow: false
		}
	},{
		path: '/sys/basic/consignor',
		component: () => import('@/views/sys/basic/consignor'),
		name: 'sys-basic-consignor',
		meta: {
			title: 'sys-basic-consignor',
			icon: '',
			menu_Id: 501,
			menuName: '货主信息',
      isOpenWindow: false
		}
	},{
		path: '/tms/basic/port',
		component: () => import('@/views/tms/basic/port'),
		name: 'tms-basic-port',
		meta: {
			title: 'tms-basic-port',
			icon: '',
			menu_Id: 1740,
			menuName: '口岸管理',
      isOpenWindow: false
		}
	},{
		path: '/sys/base/settings',
		component: () => import('@/views/sys/basic/settings'),
		name: 'sys-basic-settings',
		meta: {
			title: 'sys-basic-settings',
			icon: '',
			menu_Id: 245,
			menuName: '系统参数设置',
      isOpenWindow: false
		}
	},{
		path: '/sys/basic/code-regular',
		component: () => import('@/views/sys/basic/code-regular'),
		name: 'sys-basic-code-regular',
		meta: {
			title: 'sys-basic-code-regular',
			icon: '',
			menu_Id: 8,
			menuName: '单据编码规则',
      isOpenWindow: false
		}
	},{
		path: '/sys/basic/sys-print-bar-code',
		component: () => import('@/views/sys/basic/sys-print-bar-code'),
		name: 'sys-print-bar-code',
		meta: {
			title: 'sys-print-bar-code',
			icon: '',
			menu_Id: 127,
			menuName: '条码打印工具',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/ui-designer-user',
		component: () => import('@/views/sys/dev-tools/ui-designer'),
		name: 'sys-dev-tools-ui-designer-user',
		meta: {
			title: 'sys-dev-tools-ui-designer-user',
			icon: '',
			menu_Id: 186,
			menuName: 'UI设计',
      isOpenWindow: false
		}
	},{
		path: '/sys/basic/lang',
		component: () => import('@/views/sys/basic/lang'),
		name: 'sys-basic-lang',
		meta: {
			title: 'sys-basic-lang',
			icon: '',
			menu_Id: 1788,
			menuName: '语言包设置',
      isOpenWindow: false
		}
	},{
		path: '/tms/account/address',
		component: () => import('@/views/tms/account/address'),
		name: 'tms-account-address',
		meta: {
			title: 'tms-account-address',
			icon: '',
			menu_Id: 238,
			menuName: '货主收货地址',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/base/picker',
		component: () => import('@/views/basicInfo/base/picker'),
		name: 'basicInfo-base-picker',
		meta: {
			title: 'basicInfo-base-picker',
			icon: '',
			menu_Id: 1588,
			menuName: '提货单位',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/base/user-content',
		component: () => import('@/views/basicInfo/base/user-content'),
		name: 'basicInfo-base-user-content',
		meta: {
			title: 'basicInfo-base-user-content',
			icon: '',
			menu_Id: 1702,
			menuName: '消息管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/base/template',
		component: () => import('@/views/basicInfo/base/template'),
		name: 'basicInfo-base-template',
		meta: {
			title: 'basicInfo-base-template',
			icon: '',
			menu_Id: 1707,
			menuName: '消息模板设置',
      isOpenWindow: false
		}
	}]
},{
	path: 'product',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'product',
	alwaysShow: true,
	meta: {
		title: 'product',
		icon: '',
		menu_Id: 312,
    menuName: '产品信息维护',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/basic/product-info-port',
		component: () => import('@/views/tms/basic/product-info-port'),
		name: 'tms-basic-product-info-port',
		meta: {
			title: 'tms-basic-product-info-port',
			icon: '',
			menu_Id: 201,
			menuName: '口岸商品',
      isOpenWindow: false
		}
	},{
		path: '/sys/product/product-info',
		component: () => import('@/views/sys/product/product-info'),
		name: 'sys-product-product-info',
		meta: {
			title: 'sys-product-product-info',
			icon: '',
			menu_Id: 254,
			menuName: '商品信息',
      isOpenWindow: false
		}
	},{
		path: '/sys/product/product-info-sku',
		component: () => import('@/views/sys/product/product-info-sku'),
		name: 'sys-product-product-info-sku',
		meta: {
			title: 'sys-product-product-info-sku',
			icon: '',
			menu_Id: 1511,
			menuName: 'SKU商品信息',
      isOpenWindow: false
		}
	},{
		path: '/sys/product/product-set',
		component: () => import('@/views/sys/product/product-set'),
		name: '/sys/product/product-set',
		meta: {
			title: '/sys/product/product-set',
			icon: '',
			menu_Id: 378,
			menuName: '套装组合设置',
      isOpenWindow: false
		}
	},{
		path: '/sys/product/brand',
		component: () => import('@/views/sys/product/brand'),
		name: 'sys-product-brand',
		meta: {
			title: 'sys-product-brand',
			icon: '',
			menu_Id: 260,
			menuName: '品牌管理',
      isOpenWindow: false
		}
	},{
		path: '/sys/product/provider',
		component: () => import('@/views/sys/product/provider'),
		name: 'sys-product-provider',
		meta: {
			title: 'sys-product-provider',
			icon: '',
			menu_Id: 256,
			menuName: '供应商信息',
      isOpenWindow: false
		}
	},{
		path: '/sys/product/type',
		component: () => import('@/views/sys/product/type'),
		name: 'sys-product-type',
		meta: {
			title: 'sys-product-type',
			icon: '',
			menu_Id: 261,
			menuName: '商品类别',
      isOpenWindow: false
		}
	},{
		path: '/erp/plat/product',
		component: () => import('@/views/erp/plat/product'),
		name: 'erp-plat-product',
		meta: {
			title: 'erp-plat-product',
			icon: '',
			menu_Id: 401,
			menuName: '平台商品对应',
      isOpenWindow: false
		}
	},{
		path: '/sys/product/product-price',
		component: () => import('@/views/sys/product/product-price'),
		name: 'sys-product-product-price',
		meta: {
			title: 'sys-product-product-price',
			icon: '',
			menu_Id: 1856,
			menuName: '商品价格设置',
      isOpenWindow: false
		}
	}]
},{
	path: 'logistics',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'logistics',
	alwaysShow: true,
	meta: {
		title: 'logistics',
		icon: '',
		menu_Id: 1857,
    menuName: '物流管理',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/basic/logistics',
		component: () => import('@/views/sys/basic/logistics'),
		name: 'sys-basic-logistics',
		meta: {
			title: 'sys-basic-logistics',
			icon: '',
			menu_Id: 1858,
			menuName: '物流公司',
      isOpenWindow: false
		}
	},{
		path: '/base/freight-first',
		component: () => import('@/views/base/freight-first'),
		name: 'base-freight-first',
		meta: {
			title: 'base-freight-first',
			icon: '',
			menu_Id: 1859,
			menuName: '运价模板（头程）',
      isOpenWindow: false
		}
	},{
		path: '/base/freight-tail',
		component: () => import('@/views/base/freight-tail'),
		name: 'base-freight-tail',
		meta: {
			title: 'base-freight-tail',
			icon: '',
			menu_Id: 1860,
			menuName: '运价模板（尾程）',
      isOpenWindow: false
		}
	}]
},{
	path: 'cms',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'cms',
	alwaysShow: true,
	meta: {
		title: 'cms',
		icon: '',
		menu_Id: 236,
    menuName: 'CMS',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/cms/module',
		component: () => import('@/views/sys/cms/module'),
		name: 'sys-cms-module',
		meta: {
			title: 'sys-cms-module',
			icon: '',
			menu_Id: 300,
			menuName: '模块管理',
      isOpenWindow: false
		}
	},{
		path: '/sys/cms/content',
		component: () => import('@/views/sys/cms/content'),
		name: 'sys-cms-content',
		meta: {
			title: 'sys-cms-content',
			icon: '',
			menu_Id: 237,
			menuName: '模块内容',
      isOpenWindow: false
		}
	}]
},{
	path: 'permission',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'permission',
	alwaysShow: true,
	meta: {
		title: 'permission',
		icon: '',
		menu_Id: 2,
    menuName: '用户及权限',
    isOpenWindow: false
	},
	children:[{
		path: 'user',
		component: () => import('@/views/sys/permission/user'),
		name: 'sys-permission-user',
		meta: {
			title: 'sys-permission-user',
			icon: '',
			menu_Id: 4,
			menuName: '用户管理',
      isOpenWindow: false
		}
	},{
		path: 'dept',
		component: () => import('@/views/sys/permission/dept'),
		name: 'sys-permission-dept',
		meta: {
			title: 'sys-permission-dept',
			icon: '',
			menu_Id: 285,
			menuName: '部门管理',
      isOpenWindow: false
		}
	},{
		path: 'role',
		component: () => import('@/views/sys/permission/role'),
		name: 'sys-permission-role',
		meta: {
			title: 'sys-permission-role',
			icon: '',
			menu_Id: 18,
			menuName: '角色管理',
      isOpenWindow: false
		}
	},{
		path: 'role-auth',
		component: () => import('@/views/sys/permission/role-auth'),
		name: 'sys-permission-role-auth',
		meta: {
			title: 'sys-permission-role-auth',
			icon: '',
			menu_Id: 5,
			menuName: '角色权限设置',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/menu-auth-module',
		component: () => import('@/views/sys/dev/menu-auth-module'),
		name: 'sys-dev-menu-auth-module',
		meta: {
			title: 'sys-dev-menu-auth-module',
			icon: '',
			menu_Id: 1524,
			menuName: '数据权限模块',
      isOpenWindow: false
		}
	},{
		path: '/sys/permission/user-log',
		component: () => import('@/views/sys/permission/user-log'),
		name: 'sys-permission-user-log',
		meta: {
			title: 'sys-permission-user-log',
			icon: '',
			menu_Id: 188,
			menuName: '用户日志',
      isOpenWindow: false
		}
	}]
},{
	path: 'dev-tools',
	component: () => import("@/Layout/layout-base"),
	redirect: '/',
	name: 'dev-tools',
	alwaysShow: true,
	meta: {
		title: 'dev-tools',
		icon: '',
		menu_Id: 3,
    menuName: '开发工具',
    isOpenWindow: false
	},
	children:[{
		path: 'ui-designer',
		component: () => import('@/views/sys/dev-tools/ui-designer'),
		name: 'sys-dev-tools-ui-designer',
		meta: {
			title: 'sys-dev-tools-ui-designer',
			icon: '',
			menu_Id: 486,
			menuName: 'VueUI设计器',
      isOpenWindow: false
		}
	},{
		path: 'print-designer',
		component: () => import('@/views/sys/dev-tools/print-designer'),
		name: 'sys-dev-tools-print-designer',
		meta: {
			title: 'sys-dev-tools-print-designer',
			icon: '',
			menu_Id: 490,
			menuName: 'Vue打印设计器',
      isOpenWindow: false
		}
	},{
		path: 'layout-designer',
		component: () => import('@/views/sys/dev-tools/layout-designer'),
		name: 'sys-dev-tools-layout-designer',
		meta: {
			title: 'sys-dev-tools-layout-designer',
			icon: '',
			menu_Id: 342,
			menuName: '首页布局设计器',
      isOpenWindow: false
		}
	},{
		path: 'flow-designer',
		component: () => import('@/views/sys/dev-tools/flow-designer'),
		name: 'sys-dev-tools-flow-designer',
		meta: {
			title: 'sys-dev-tools-flow-designer',
			icon: '',
			menu_Id: 1576,
			menuName: '流程设计器',
      isOpenWindow: false
		}
	},{
		path: 'bi-designer',
		component: () => import('@/views/sys/dev-tools/bi-designer'),
		name: 'sys-dev-tools-bi-designer',
		meta: {
			title: 'sys-dev-tools-bi-designer',
			icon: '',
			menu_Id: 1577,
			menuName: '报表设计器',
      isOpenWindow: false
		}
	},{
		path: '/icons/font',
		component: () => import('@/views/icons/font'),
		name: 'icons-font',
		meta: {
			title: 'icons-font',
			icon: '',
			menu_Id: 488,
			menuName: '字体图标',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/mvctableinfo',
		component: () => import('@/views/sys/dev/mvctableinfo'),
		name: 'sys-dev-mvctableinfo',
		meta: {
			title: 'sys-dev-mvctableinfo',
			icon: '',
			menu_Id: 6,
			menuName: 'Mvc开发中心',
      isOpenWindow: false
		}
	},{
		path: '/icons/svg',
		component: () => import('@/views/icons/svg'),
		name: 'icons-svg',
		meta: {
			title: 'icons-svg',
			icon: '',
			menu_Id: 487,
			menuName: 'SVG图标',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/code-regular',
		component: () => import('@/views/sys/dev/code-regular'),
		name: 'sys-dev-code-regular',
		meta: {
			title: 'sys-dev-code-regular',
			icon: '',
			menu_Id: 7,
			menuName: '默认单据编码',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/menu',
		component: () => import('@/views/sys/dev/menu'),
		name: 'sys-dev-menu',
		meta: {
			title: 'sys-dev-menu',
			icon: '',
			menu_Id: 496,
			menuName: 'Vue菜单设置',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/menu-app',
		component: () => import('@/views/sys/dev/menu-app'),
		name: 'sys-dev-menu-app',
		meta: {
			title: 'sys-dev-menu-app',
			icon: '',
			menu_Id: 1723,
			menuName: 'APP菜单设置',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/import',
		component: () => import('@/views/sys/dev/import'),
		name: 'sys-dev-import',
		meta: {
			title: 'sys-dev-import',
			icon: '',
			menu_Id: 319,
			menuName: '导入设置',
      isOpenWindow: false
		}
	},{
		path: 'export-setting',
		component: () => import('@/views/sys/basic/export-setting'),
		name: 'sys-basic-export-setting',
		meta: {
			title: 'sys-basic-export-setting',
			icon: '',
			menu_Id: 384,
			menuName: '导出设置',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/basicInfo',
		component: () => import('@/views/sys/dev/basicInfo'),
		name: 'sys-dev-basicInfo',
		meta: {
			title: 'sys-dev-basicInfo',
			icon: '',
			menu_Id: 1669,
			menuName: '系统设置',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/dropdown',
		component: () => import('@/views/sys/dev/dropdown'),
		name: 'sys-dev-dropdown',
		meta: {
			title: 'sys-dev-dropdown',
			icon: '',
			menu_Id: 10,
			menuName: '下拉框设置',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/param-type',
		component: () => import('@/views/sys/dev/param-type'),
		name: 'sys-dev-param-type',
		meta: {
			title: 'sys-dev-param-type',
			icon: '',
			menu_Id: 11,
			menuName: '下拉框值设置',
      isOpenWindow: false
		}
	}]
}]
},
{
	path: '*',
	redirect: '/404',
	hidden: true
}]