var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        visible: _vm.currentVisible,
        width: "600px",
        title: "修改密码",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.currentVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form-inline w-400",
          attrs: { model: _vm.form }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.formLabelWidth,
                label: "请输入密码："
              }
            },
            [
              _c("el-input", {
                attrs: { type: _vm.passwordType, "auto-complete": "off" },
                model: {
                  value: _vm.form.password,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password"
                }
              }),
              _c(
                "span",
                { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.passwordType == "password" ? "eye" : "eye-open"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.formLabelWidth,
                label: "重复输入密码："
              }
            },
            [
              _c("el-input", {
                attrs: { type: _vm.passwordType, "auto-complete": "off" },
                model: {
                  value: _vm.form.password_repeat,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "password_repeat", $$v)
                  },
                  expression: "form.password_repeat"
                }
              }),
              _c(
                "span",
                { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.passwordType == "password" ? "eye" : "eye-open"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.currentVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.modifypwd } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }