
<template>
  <el-dialog v-dialogDrag :visible.sync="currentVisible" width="600px" title="修改密码" append-to-body>
    <el-form ref="form" :model="form" class="demo-form-inline w-400">
      <el-form-item :label-width="formLabelWidth" label="请输入密码：">
        <el-input v-model="form.password" :type="passwordType" auto-complete="off"></el-input>
        <span class="show-pwd" @click="showPwd">
          <svg-icon :icon-class="passwordType=='password'?'eye':'eye-open'" />
        </span>
      </el-form-item>
      <el-form-item :label-width="formLabelWidth" label="重复输入密码：">
        <el-input v-model="form.password_repeat" :type="passwordType" auto-complete="off"></el-input>
        <span class="show-pwd" @click="showPwd">
          <svg-icon :icon-class="passwordType=='password'?'eye':'eye-open'" />
        </span>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="currentVisible = false">取 消</el-button>
      <el-button type="primary" @click="modifypwd">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "order-barcode",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      id: "",
      passwordType: "password",
      form: {
        password: "",
        password_repeat: ""
      },
      formLabelWidth: "150px",
      user_Id: ""
    };
  },
  computed: {
    currentVisible: {
      get: function() {
        return this.visible;
      },
      set: function(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  mounted() {
    const userInfo = this.common.getUserInfo();
    this.user_Id = userInfo.user_Id;
  },
  methods: {
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
    },
    setUser_Id(user_Id) {
      this.user_Id = user_Id;
    },
    modifypwd() {
      // 密码
      var password = this.form.password;
      if (password.length < 3) {
        this.$notify.error({
          title: "错误提示",
          message: "密码必须大于3位！"
        });
        return;
      }

      // 重复密码
      var password_repeat = this.form.password_repeat;
      if (password_repeat.length < 3) {
        this.$notify.error({
          title: "错误提示",
          message: "密码必须大于3位！"
        });
        return;
      } else if (password_repeat !== password) {
        this.$notify.error({
          title: "错误提示",
          message: "两次密码不一致！"
        });
        return;
      }

      const url = "/api/sys/user/modifyPwd";
      const params = {
        user_Id: this.user_Id,
        userPwd: password,
        repeatPassWord: password_repeat
      };
      var callback = res => {
        this.common.showMsg(res);
        if (res.result) {
          this.currentVisible = false;
          if (this.$parent && this.$parent.editorOptions) {
            this.$parent.editorOptions.config.visible = false;
          }
        }
      };
      this.common.ajax(url, params, callback, this.$refs.form);
    }
  }
};
</script>

<style lang="less" scoped>
@deep: ~">>>";

@{deep} .el-dialog__body {
  padding: 0px 10px;
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.show-pwd {
  position: absolute;
  right: 10px;
  top: 2px;
  font-size: 16px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
</style>
